import { HitType } from '@frontastic-engbers/types/product/Hit';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Product } from '@frontastic-engbers/types/product/Product';

export const getOutfitMainItem = (product: Product | Variant | HitType, isEmilio: boolean) => {
  return isEmilio
    ? product?.attributes?.OutfitMainItem_3
    : product?.attributes?.OutfitMainItem_2?.length > 0
      ? product?.attributes?.OutfitMainItem_2
      : product?.attributes?.OutfitMainItem_3;
};
