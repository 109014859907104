import { CheckoutData } from '@frontastic-engbers/types/engbers-custom';

export const NOT_CHECKED_SOLVENCY_RATING = 0;
export const NEGATIVE_SOLVENCY_RATING = 1;
export const POSITIVE_SOLVENCY_RATING = 2;

export const defaultCheckoutData: CheckoutData = {
  billingAddress: {
    salutation: '',
    firstName: '',
    lastName: '',
    streetName: '',
    streetNumber: '',
    city: '',
    postalCode: '',
    country: 'DE',
  },
  shippingAddress: {
    salutation: '',
    firstName: '',
    lastName: '',
    streetName: '',
    streetNumber: '',
    company: '',
    postalCode: '',
    city: '',
    country: 'DE',
  },
  storeDeliveryAddress: {
    country: 'DE',
  },
  packstationAddress: {
    country: 'DE',
  },
  shippingMethod: 'billingAddress',
  paymentMethod: undefined,
  customerData: {
    birthdayDay: undefined,
    birthdayMonth: undefined,
    birthdayYear: undefined,
    email: '',
    emailRepeat: '',
    agbAccepted: false,
    password: '',
    passwordRepeat: '',
    postSubmit: false,
    cardAgbAccepted: false,
  },
  newsletter: {
    enNewsletterAccepted: false,
    emilioNewsletterAccepted: false,
    xxlNewsletterAccepted: false,
  },
  commentOnOrder: '',
  userAgbAccepted: false,
  solvency: {
    result: NOT_CHECKED_SOLVENCY_RATING,
    accepted: false,
  },
  isUnselectedShippingAddress: false,
};
