import { Address } from '@frontastic-engbers/types/account/Address';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { Order } from '@frontastic-engbers/types/cart/Order';
import { ShippingCosts } from '@frontastic-engbers/types/cart/ShippingInfo';
import { ShippingMethod } from '@frontastic-engbers/types/cart/ShippingMethod';
import { CheckoutData, ShippingProvider, ShippingType } from '@frontastic-engbers/types/engbers-custom';
import { CurrencyHelpers } from '../currencyHelpers';
import { cartHasFreeShippingItemsOnly } from '@frontastic-engbers/helpers/utils/cartHasFreeShippingItemsOnly';

export const getSelectedShippingAddress = (
  checkoutData: CheckoutData,
  shippingMethod?: ShippingType,
): Address | undefined => {
  let shippingAddress: Address | undefined;

  switch (shippingMethod ?? checkoutData.shippingMethod) {
  case 'shippingAddress':
    shippingAddress = checkoutData.shippingAddress;
    break;
  case 'packStation':
    shippingAddress = checkoutData.packstationAddress;
    break;
  case 'store':
    shippingAddress = checkoutData.storeDeliveryAddress;
    break;
  default:
    shippingAddress = checkoutData.billingAddress;
    break;
  }

  return shippingAddress;
};

export const getShippingCostsByCountry = (shippingMethod?: ShippingMethod, country?: string) => {
  const shippingCosts = shippingMethod?.rates?.find((rate) =>
    rate?.locations?.find((location) => location.country === country),
  );

  return shippingCosts?.price;
};

export const getFormattedShippingCostsByCountry = (shippingMethod?: ShippingMethod, country?: string) => {
  if (!shippingMethod) {
    return '';
  }

  const shippingCosts = getShippingCostsByCountry(shippingMethod, country);

  if (!shippingCosts) {
    return '';
  }

  return CurrencyHelpers.formatForCurrency(shippingCosts || {}, true);
};

export const computeShippingTime = (source: Order, shippingProvider: ShippingProvider[], shippingMethods?: ShippingMethod[]) => {
  if (!shippingProvider || !source?.shippingInfo?.shippingMethodId) {
    return null;
  }

  const { shippingInfo, shippingType, shippingAddress } = source;

  const shippingMethodId =
    shippingType === 'store' && shippingMethods?.length
      ? shippingMethods.find((method) => method.isDefault)?.shippingMethodId || shippingInfo.shippingMethodId
      : shippingInfo.shippingMethodId;

  if (!shippingMethodId) {
    return null;
  }

  const matchedProvider = shippingProvider.find(
    (provider) => provider.shippingCompany === shippingMethodId
  );

  if (!matchedProvider) {
    return null;
  }

  const deliveryTime = shippingAddress?.country?.toLowerCase() === 'de'
    ? matchedProvider.deliveryTimeDE
    : matchedProvider.deliveryTimeEU;

  return matchedProvider.deliveryTimeText.replace('{days}', deliveryTime);
};

export const computeShippingCosts = (
  cart: Cart,
  data: CheckoutData,
  shippingMethods: ShippingMethod[],
  getFallbackCosts: (country?: string) => string,
): ShippingCosts => {
  if (!cart || !data) {
    const fallbackCosts = getFallbackCosts();
    return {
      billing: fallbackCosts,
      shipping: fallbackCosts,
      store: fallbackCosts,
      packstation: fallbackCosts
    };
  }

  if (!shippingMethods) {
    return {
      billing: getFallbackCosts(cart.billingAddress?.country),
      shipping: getFallbackCosts(data.shippingAddress?.country),
      store: getFallbackCosts(data.storeDeliveryAddress?.country),
      packstation: getFallbackCosts(data.packstationAddress?.country),
    };
  }

  const defaultShippingMethod = shippingMethods.find((method) => method.isDefault);
  const packstationMethod = shippingMethods.find((method) => method.isPackstationMethod);
  const findShippingProvider = (id?: string) =>
    shippingMethods.find((method) => method.shippingMethodId === id);

  let shippingProvider = defaultShippingMethod;

  if (cart.shippingType === 'billingAddress' || cart.shippingType === 'shippingAddress' || cartHasFreeShippingItemsOnly(cart)) {
    shippingProvider = findShippingProvider(cart.shippingInfo?.shippingMethodId) ?? shippingProvider;
  } else if (cart.shippingInfo?.previousShippingProvider) {
    shippingProvider = findShippingProvider(cart.shippingInfo.previousShippingProvider) ?? shippingProvider;
  }

  return {
    billing: getFormattedShippingCostsByCountry(shippingProvider, cart.billingAddress?.country),
    shipping: getFormattedShippingCostsByCountry(shippingProvider, data.shippingAddress?.country),
    store: getFormattedShippingCostsByCountry(defaultShippingMethod, data.storeDeliveryAddress?.country),
    packstation: getFormattedShippingCostsByCountry(cartHasFreeShippingItemsOnly(cart) ? shippingProvider : packstationMethod, data.storeDeliveryAddress?.country),
  };
};
