import React from 'react';
import { useCart } from '@frontastic-engbers/lib';
import { VariantSizeSuggestion } from '@frontastic-engbers/helpers/utils/sizeSuggestion';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { IFTLinkReference, IFTPageFolderReference, SoldOutVariants } from '@frontastic-engbers/types/engbers-custom';
import Item from './item';
import RefusedGiftItem from './refusedGiftItem';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { RefusedGift } from '@frontastic-engbers/types/cart/RefusedGift';
interface Props {
  updateItemQuantity: (lineItem: LineItem, newQuantity: number) => Promise<void>;
  updateItemSize: (variant: Variant, quantity: number, shouldMutate?: boolean) => Promise<void>;
  deleteItem: (lineItem: LineItem) => Promise<void>;
  undoRefuseGift?: (refusedGift: RefusedGift) => Promise<void>;
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  shippingTime?: string;
  isCheckout?: boolean;
  soldOutItems?: SoldOutVariants[];
  pathSizeSuggestion?: IFTLinkReference | IFTPageFolderReference;
}
const ItemList = ({
  updateItemQuantity,
  updateItemSize,
  deleteItem,
  undoRefuseGift,
  setIsUpdating,
  shippingTime,
  isCheckout = false,
  soldOutItems,
  pathSizeSuggestion
}: Props) => {
  const {
    data: cart
  } = useCart();
  return <section aria-labelledby="cart-heading" data-sentry-component="ItemList" data-sentry-source-file="itemList.tsx">
      <ul>
        {[...(cart?.lineItems || [])].sort((a, b) => new Date(b.lastModifiedAt).getTime() - new Date(a.lastModifiedAt).getTime())?.map(lineItem => <li key={lineItem.lineItemId + lineItem.cloned}>
              <Item lineItem={lineItem} updateItemQuantity={updateItemQuantity} updateItemSize={updateItemSize} deleteItem={deleteItem} setIsUpdating={setIsUpdating} shippingTime={shippingTime} isCheckout={isCheckout} sizeSuggestion={new VariantSizeSuggestion(lineItem.variant).getSizeSuggestion(cart)} soldOutItems={soldOutItems} pathSizeSuggestion={pathSizeSuggestion} />
            </li>)}
        {undoRefuseGift && cart?.refusedGifts?.map(refusedGift => <li key={`gift-${refusedGift.discount.id}`}>
              <RefusedGiftItem refusedGift={refusedGift} undoRefuse={undoRefuseGift} isCheckout={isCheckout} />
            </li>)}
      </ul>
    </section>;
};
export default ItemList;