import {
  DataLayer,
  EcondaDataLayer,
  EcondaProductDataLayer,
  EcondaWishlistToCartProduct,
} from '@frontastic-engbers/types/tagmanager/DataLayer';
import { EcommerceDataLayerItem } from '@frontastic-engbers/types/tagmanager/EcommerceDataLayerItem';
import { Money } from '@frontastic-engbers/types/product/Money';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { Product } from '@frontastic-engbers/types/product/Product';
import { CurrencyHelpers } from '@frontastic-engbers/helpers/currencyHelpers';
import { CategoryMapper } from '@engbers/shop-backend/online-shop/utils/CategoryMapper';
import { Order } from '@frontastic-engbers/types/cart/Order';
import { joinDiscounts, joinDiscountsValue } from '@frontastic-engbers/helpers/dataLayerHelper/couponCodeDataHelper';
import { getProductGroup } from '@frontastic-engbers/helpers/dataLayerHelper/econdaDataHelper';
import md5 from 'crypto-js/md5';

export class DataLayerFactory {
  static readonly ATTRIBUTE_BRAND = 'DesignerName';

  static getClearEcommerceLayer(): DataLayer {
    return { ecommerce: null };
  }

  static getViewItemLayer(variant: Variant, datalayerItems: EcommerceDataLayerItem[], payload?: any): DataLayer {
    payload = payload ?? {};

    return {
      event: 'view_item',
      ecommerce: {
        currency: variant.price?.currencyCode ?? 'EUR',
        value: DataLayerFactory.convertCentAmount({
          currencyCode: variant.price?.currencyCode ?? 'EUR',
          centAmount: variant.discountedPrice ? variant.discountedPrice.centAmount : variant.price?.centAmount,
          fractionDigits: variant.price?.fractionDigits ?? 2,
        }),
        items: datalayerItems,
        ...payload,
      },
    };
  }

  static getViewItemOutfitLayer(
    variant: Variant,
    outfitSum: number,
    datalayerItems: EcommerceDataLayerItem[],
    payload?: any,
  ): DataLayer {
    payload = payload ?? {};

    return {
      event: 'view_item_outfit',
      ecommerce: {
        currency: variant.price?.currencyCode ?? 'EUR',
        value: DataLayerFactory.convertCentAmount({
          currencyCode: 'EUR',
          centAmount: outfitSum,
          fractionDigits: 2,
        }),
        items: datalayerItems,
        ...payload,
      },
    };
  }

  static getViewItemListLayer(
    listId: string,
    listName: string,
    datalayerItems: EcommerceDataLayerItem[],
    payload?: any,
  ): DataLayer {
    payload = payload ?? {};

    return {
      event: 'view_item_list',
      ecommerce: {
        item_list_id: listId,
        item_list_name: listName,
        items: datalayerItems,
        ...payload,
      },
    };
  }

  static getAddToCartLayer(datalayerItems: any, addToCartSum: any, payload?: any): DataLayer {
    payload = payload ?? {};

    return {
      event: 'add_to_cart',
      ecommerce: {
        currency: 'EUR',
        value: addToCartSum,
        items: datalayerItems,
        ...payload,
      },
    };
  }

  static getRemoveFromCartLayer(datalayerItems: any, removeFromCartSum: any, payload?: any): DataLayer {
    payload = payload ?? {};

    return {
      event: 'remove_from_cart',
      ecommerce: {
        currency: 'EUR',
        value: removeFromCartSum,
        items: datalayerItems,
        ...payload,
      },
    };
  }

  static getAddToWishlistLayer(datalayerItems: any, sum: Money, payload?: any): DataLayer {
    payload = payload ?? {};

    return {
      event: 'add_to_wishlist',
      ecommerce: {
        currency: 'EUR',
        value: DataLayerFactory.convertCentAmount(sum),
        items: datalayerItems,
        ...payload,
      },
    };
  }

  static getSelectItemLayer(datalayerItems: EcommerceDataLayerItem[], payload?: any): DataLayer {
    payload = payload ?? {};

    return {
      event: 'select_item',
      ecommerce: {
        items: datalayerItems,
        ...payload,
      },
    };
  }

  static getCustomEventLayer(event: string, payload?: any): DataLayer {
    return {
      event: event,
      ...payload,
    };
  }

  static getEcommerceLayer(
    event: string,
    datalayerItems: EcommerceDataLayerItem[],
    value?: Money,
    couponCode?: string,
    payload?: any,
  ): DataLayer {
    payload = payload ?? {};

    return {
      event: event,
      ecommerce: {
        value: DataLayerFactory.convertCentAmount(value),
        currency: value?.currencyCode ?? 'EUR',
        coupon: couponCode,
        items: datalayerItems,
        ...payload,
      },
    };
  }

  static getOrderSubmitLayer(order: Order) {
    const awinCookie = localStorage.getItem('awinCookie') ? 'aw' : 'engbers Onlineshop';
    const accessoriesGroups = ['EN310', 'EN320', 'EN330', 'EN340', 'EA310', 'EA320', 'EA330', 'EA340'];
    const voucherValue = joinDiscountsValue(order.discountCodes, order.customLineItems);
    const voucherCode = joinDiscounts(order.discountCodes, order.customLineItems);

    return {
      event: 'emitOrderSubmitted',
      orderId: order.orderId,
      nettoOrderValue: DataLayerFactory.convertCentAmount(order.taxed?.amount),
      ecommerce: {
        purchase: {
          actionField: {
            id: order.orderId,
            affiliation: awinCookie,
            revenue: DataLayerFactory.convertCentAmount(order.sum),
            tax: order.taxed?.taxPortions?.length
              ? DataLayerFactory.convertCentAmount(order.taxed.taxPortions[0]?.amount)
              : undefined,
            shipping: DataLayerFactory.convertCentAmount(order.shippingInfo.price),
          },
          currencyCode: order.sum?.currencyCode ?? 'EUR',
          products: order.lineItems?.map((lineItem) => {
            return {
              id: lineItem.variant?.sku,
              name: lineItem.name,
              price: DataLayerFactory.convertCentAmount(lineItem.variant?.discountedPrice ?? lineItem.variant?.price),
              brand: lineItem.variant?.attributes?.DesignerName,
              category: `${CategoryMapper.getCategoryPath(
                lineItem.variant?.attributes?.GoodsGroupWithoutPurchGroup,
              ).join('|')} | ${lineItem.variant?.attributes?.GoodsGroupWithoutPurchGroup}`,
              variant: lineItem.variant?.attributes?.Sizing,
              quantity: lineItem.count,
            };
          }),
        },
      },
      totalQty: order.lineItems?.reduce((count, lineItem) => count + (lineItem.count ?? 0), 0),
      totalOrderVal: DataLayerFactory.convertCentAmount(order.sum),
      completeOrderVal: DataLayerFactory.convertCentAmount({
        currencyCode: 'EUR',
        centAmount: order.lineItems?.reduce((price, lineItem) => price + (lineItem.totalPrice?.centAmount ?? 0), 0),
        fractionDigits: 2,
      }),
      completeTax: order.taxed?.taxPortions?.length
        ? DataLayerFactory.convertCentAmount(order.taxed.taxPortions[0]?.amount)
        : undefined,
      voucherValue: voucherValue && voucherValue > 0 ? voucherValue : '',
      voucherCode: voucherCode,
      payingMethod: order.payments ? order.payments[0]?.paymentMethodName : undefined,
      payingMethodCost: 0,
      shippingCost: DataLayerFactory.convertCentAmount(order.shippingInfo.price),
      shippingMethod: order.shippingInfo.name,
      transactionId: order.orderId,
      transactionAffiliation: awinCookie,
      transactionTotal: DataLayerFactory.convertCentAmount(order.sum),
      transactionAffiliateGroup: order.lineItems?.find((lineItem) =>
        accessoriesGroups.includes(lineItem.variant?.attributes?.ProductGroup),
      )
        ? 'ACCESSORIES'
        : 'SALE',
      email: order?.email ? md5(order.email).toString() : '',
    };
  }

  static getPurchaseLayer(
    sum: Money,
    tax: number,
    shipping: Money,
    transactionId: string,
    datalayerItems: EcommerceDataLayerItem[],
    couponCode: string | undefined,
    email?: string,
    discount?: number,
    payload?: any,
  ): DataLayer {
    payload = payload ?? {};

    return {
      event: 'purchase',
      email: email,
      ecommerce: {
        currency: sum.currencyCode ?? 'EUR',
        value: DataLayerFactory.convertCentAmount(sum),
        tax: DataLayerFactory.convertCentAmount({
          currencyCode: 'EUR',
          centAmount: tax,
          fractionDigits: 2,
        }),
        shipping: DataLayerFactory.convertCentAmount(shipping),
        transaction_id: transactionId,
        coupon: couponCode,
        discount: discount,
        items: datalayerItems,
        ...payload,
      },
    };
  }

  static getEcondaEventLayer(econdaData: EcondaDataLayer): EcondaDataLayer & { event: string } {
    return {
      event: 'econda',
      ecSiteId: econdaData.ecSiteId,
      ecContent: econdaData.ecContent,
      ecPageId: econdaData.ecPageId,
      ecVisitorID: econdaData.ecVisitorID,
      ecCustomProperties: econdaData.ecCustomProperties,
      ecLangId: econdaData.ecLangId,
      ecCategory: econdaData.ecCategory,
      ecOrderProcess: econdaData.ecOrderProcess,
      ecProducts: econdaData.ecProducts,
      ecBillingId: econdaData.ecBillingId,
      ecBillingSum: econdaData.ecBillingSum,
      ecCustomerid: econdaData.ecCustomerid,
      ecCustomerLocation: econdaData.ecCustomerLocation,
      ecSearchPhrase: econdaData.ecSearchPhrase,
      ecSearchResults: econdaData.ecSearchResults,
    };
  }

  static getEcondaCartLayer(
    ecProducts: EcondaProductDataLayer[] | undefined,
    event: string,
  ): {
    event: string;
    ecProducts: EcondaProductDataLayer[] | undefined;
  } {
    return {
      event,
      ecProducts,
    };
  }

  static getEcondaWishlistLayer(
    lineItem: { id: string; quantity: string | number; date: Date | string },
    event: string,
    type: string,
    visitorID: string,
  ): {
    event: string;
    ec_Event: string;
    ecVisitorID: string;
    ecProducts: { id: string; quantity: string | number; date: Date | string };
  } {
    return {
      event,
      ec_Event: type,
      ecVisitorID: visitorID,
      ecProducts: {
        id: lineItem.id,
        quantity: lineItem.quantity,
        date: lineItem.date,
      },
    };
  }

  static getEcondaWishlistToCartLayer(
    product: Product,
    selectedVariant: Variant | undefined,
    visitorID: string,
  ): {
    event: string;
    ec_Event: string;
    ecVisitorID: string;
    ecProducts: EcondaWishlistToCartProduct;
  } {
    return {
      event: 'econdaMoveFromWishlistToCart',
      ec_Event: 'cart_wish',
      ecVisitorID: visitorID,
      ecProducts: {
        name: product.name ?? '',
        id: product.productId ?? '',
        price: CurrencyHelpers.formatForCurrency(
          selectedVariant?.discountedPrice?.centAmount ??
            selectedVariant?.price?.centAmount ??
            product.variants[0]?.discountedPrice?.centAmount ??
            product.variants[0]?.price?.centAmount ??
            0,
          false,
        ),
        brand: selectedVariant?.attributes?.DesignerName ?? product?.attributes?.DesignerName,
        variant: selectedVariant?.attributes?.Sizing,
        category: getProductGroup(product),
        quantity: 1,
      },
    };
  }

  static convertCentAmount(price: Money | undefined): number {
    const fractionDigits = price?.fractionDigits ?? 2;
    return (price?.centAmount ?? 0) / 10 ** fractionDigits;
  }
}
