import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useMediaQuery } from 'react-responsive';
import { useRouter } from 'next/router';
import { getActiveShops, setActiveShops, useCurrentShop } from '@frontastic-engbers/lib';
import { HeaderMain } from '@engbers/components/header-main';
import { HeaderMinimized } from '@engbers/components/header-minimized';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import LoadingPlaceholder from '@frontastic-engbers/lib/lib/loading-placeholder';
export const HeaderTastic = ({
  data
}) => {
  const {
    asPath
  } = useRouter();
  const activeShops = getActiveShops();
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const currentSection = useCurrentShop();
  const HeaderServiceArea = dynamic(() => import('@engbers/components/header-service-area').then(module => module.HeaderServiceArea), {
    loading: () => <LoadingPlaceholder height="40px" />
  });
  data.logos.forEach(logo => {
    if (!asPath.includes(logo.relativePath + '-')) {
      activeShops[logo.activeShop] = asPath.includes(logo.relativePath);
    }
  });
  setActiveShops(activeShops);
  useEffect(() => {
    document.body.classList.toggle('is-en-germany', currentSection === 'engbers-germany');
  }, [currentSection]);
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  return <FullPageWidthWrapper data-sentry-element="FullPageWidthWrapper" data-sentry-component="HeaderTastic" data-sentry-source-file="index.tsx">
      {isMinWidthMedium ? <HeaderMinimized mobileCheckoutLogo={data.mobileCheckoutLogo} mobileCheckoutLogoLink={data.mobileCheckoutLogoLink} trustedShopIcon={data.trustedShopIcon} trustedShopText={data.trustedShopText} isCheckout={false} isEnGermany={currentSection === 'engbers-germany'} /> : <>
          <HeaderServiceArea services={data.services} isFullWidth={data.isFullWidth} />
          <HeaderMain logos={data.logos} items={data.items} />
        </>}
    </FullPageWidthWrapper>;
};