import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import styles from './markdown.module.scss';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
interface Props {
  text: string;
  textSize?: number;
  textAlign?: 'left' | 'center' | 'right';
  className?: string;
  variables?: object;
  linkToNewTab?: boolean;
  disableMargin?: boolean;
}
const variableRegex = /\{{2}(\w+)}{2}/g;
const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"(?:[^>]*?)>([\s\S]*?)<\/a>/gi;
export const Markdown: React.FC<Props> = ({
  text,
  textSize,
  textAlign,
  className,
  variables = {},
  linkToNewTab,
  disableMargin
}) => {
  const isCommercetoolsLanguageMapper = text && typeof text === 'object' && Object.keys(text)[0].toString().includes('-');
  const {
    t
  } = useI18n(isCommercetoolsLanguageMapper);
  text = text && typeof text === 'object' ? t(text) : text;
  const [formatedText, setFormatedText] = useState<React.ReactNode>(null);
  const currentText = useMemo(() => text?.replace(variableRegex, (_, variable) => variables[variable] || ''), [text]);
  const convertToHTML = async markdownText => {
    const markdownModule = await import('markdown');
    const markdown = markdownModule.default.markdown;
    if (linkToNewTab) {
      return markdown.toHTML(markdownText).replace(linkRegex, (match, url, linkText) => {
        return `<a href="${url}" target="_blank">${linkText}</a>`;
      });
    }
    return markdown.toHTML(markdownText);
  };
  useEffect(() => {
    if (typeof text !== 'string') {
      return null;
    }
    convertToHTML(currentText).then(async res => {
      const Unified = await import('@frontastic-engbers/lib/lib/utils/markdown').then(module => module.default);
      setFormatedText(Unified.parse(res));
    });
  }, [text, currentText]);
  if (!text && !formatedText) {
    return <></>;
  }
  return <div style={{
    fontSize: textSize > 0 ? `${textSize}px` : undefined,
    textAlign: textAlign ? textAlign : undefined
  }} className={classnames('markdown', styles.markdownWrap, className, {
    [styles.customFont]: textSize,
    [styles.disableMargin]: disableMargin
  })} data-sentry-component="Markdown" data-sentry-source-file="index.tsx">
      {React.isValidElement(formatedText) && formatedText.props.children}
    </div>;
};