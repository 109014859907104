import { useMemo } from 'react';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { ShippingCosts } from '@frontastic-engbers/types/cart/ShippingInfo';
import { ShippingMethod } from '@frontastic-engbers/types/cart/ShippingMethod';
import { CheckoutData } from '@frontastic-engbers/types/engbers-custom';
import { computeShippingCosts } from '../utils/getShippingInfo';

export const useShippingCosts = (
  cart: Cart,
  data: CheckoutData,
  shippingMethods: ShippingMethod[],
  getFallbackCosts: (country?: string) => string,
): ShippingCosts => {
  return useMemo(() => {
    return computeShippingCosts(cart, data, shippingMethods, getFallbackCosts);
  }, [
    cart,
    data?.shippingAddress?.country,
    data?.storeDeliveryAddress?.country,
    data?.packstationAddress?.country,
    shippingMethods,
    getFallbackCosts,
  ]);
};
