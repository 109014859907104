import { useRefinementList } from 'react-instantsearch';
import { InputCheckbox } from '@engbers/components';
import { handleFilterDataLayer } from '@frontastic-engbers/helpers/dataLayerHelper';
import { IFacetProps } from './types';
import styles from './facets.module.scss';
import React, { useEffect, useState } from 'react';
import ApplyFilterButton from './applyFilterButton';
import { useProductList } from '../../context';
const TermFacet: React.FC<IFacetProps> = ({
  label,
  attribute,
  filterOnSelection = true,
  onApplyFilter,
  wrapper: Wrapper = React.Fragment,
  showItemCount = false
}) => {
  const [selectedTerms, setSelectedTerms] = useState<Set<string>>(new Set());
  const {
    updateSelectedFacets
  } = useProductList();
  const {
    items,
    refine
  } = useRefinementList({
    attribute,
    limit: 100
  });
  const updateSelectedTerms = (term: string) => {
    if (filterOnSelection) {
      refine(term);
      handleFilterDataLayer(items, label, term);
      return;
    }
    setSelectedTerms(prevSelected => {
      const updatedSelection = new Set(prevSelected);
      updatedSelection.has(term) ? updatedSelection.delete(term) : updatedSelection.add(term);
      return updatedSelection;
    });
  };
  useEffect(() => {
    const refinedTerms = new Set<string>();
    items.forEach(item => {
      if (item.isRefined) {
        refinedTerms.add(item.value);
      }
    });
    setSelectedTerms(refinedTerms);
  }, [items]);
  useEffect(() => {
    updateSelectedFacets({
      [attribute]: Array.from(selectedTerms)
    });
  }, [selectedTerms, updateSelectedFacets, attribute]);
  return <>
      <div className={styles.facetValueWrapper}>
        {items.map(term => <Wrapper key={term.value + 'wrapper'}>
            <div className={styles.termFacetValue}>
              <InputCheckbox id={term.value} name={term.value} checked={selectedTerms.has(term.value)} onChange={() => updateSelectedTerms(term.value)} label={`${term.label}${showItemCount ? ` (${term.count.toString()})` : ``}`} />
            </div>
          </Wrapper>)}
      </div>
      {!filterOnSelection && <ApplyFilterButton facets={{
      [attribute]: Array.from(selectedTerms)
    }} onApplyFilters={() => onApplyFilter(attribute, label, Array.from(selectedTerms))} />}
    </>;
};
export default TermFacet;