import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Script from 'next/script';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { isDevelopment } from '@frontastic-engbers/helpers/utils/environment';
import { createHostedTokenizationPage } from '@frontastic-engbers/lib/actions/payone';
import { PaymentErrors } from '@frontastic-engbers/types/engbers-custom';
import { Button, CustomMarkdown } from '@engbers/components';
import styles from './payment-inputs.module.scss';
import { usePayments } from '../../../context/PaymentProvider';
type TokenizationResult = {
  error?: string;
  hostedTokenizationId: string;
  success: boolean;
};
interface IPaymentInputs {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  onClose: () => void;
  setErrors: React.Dispatch<React.SetStateAction<Partial<PaymentErrors>>>;
  paymentInputsRef: React.MutableRefObject<HTMLDivElement>;
  creditCardAuthorizedInfo: string;
}
export const PaymentInputs: React.FC<IPaymentInputs> = ({
  setLoading,
  onConfirm,
  onClose,
  setErrors,
  paymentInputsRef,
  creditCardAuthorizedInfo
}) => {
  const {
    setHostedTokenizationId,
    setPaymentProductId,
    selectedPaymentMethod
  } = usePayments();
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const [tokenizer, setTokenizer] = useState(undefined);
  const [scriptReady, setScriptReady] = useState<boolean>(false);
  const [successfulSubmit, setSuccessfulSubmit] = useState<boolean>(false);
  const submitForm = () => {
    if (!tokenizer) {
      return;
    }
    tokenizer.submitTokenization().then(async (result: TokenizationResult) => {
      if (result.success) {
        setHostedTokenizationId(result.hostedTokenizationId);
        setSuccessfulSubmit(true);
        tokenizer.destroy();
        return;
      }
      setSuccessfulSubmit(false);
    });
  };
  useEffect(() => {
    setLoading(tokenizer === undefined);
  }, [tokenizer]);
  useEffect(() => {
    if (!scriptReady) {
      return;
    }
    setLoading(true);
    const createHostedTokenizationPageAsync = async () => {
      let hasError = false;
      try {
        const {
          hostedTokenizationUrl,
          error
        } = await createHostedTokenizationPage();
        if (error) {
          hasError = true;
          return;
        }

        // @ts-ignore
        const tokenizer = new Tokenizer(hostedTokenizationUrl, 'div-hosted-tokenization', {
          hideCardholderName: false,
          paymentProductUpdatedCallback: result => setPaymentProductId(result.selectedPaymentProduct.id)
        });
        tokenizer.initialize().then(() => setTokenizer(tokenizer)).catch(() => {
          hasError = true;
        });
      } catch (e) {
        hasError = true;
      }
      setErrors(errors => ({
        ...errors,
        generalError: hasError
      }));
    };
    createHostedTokenizationPageAsync();
  }, [scriptReady]);
  return <>
      <Script src={`https://payment.${isDevelopment ? 'preprod' : ''}.payone.com/hostedtokenization/js/client/tokenizer.min.js`} onReady={() => setScriptReady(true)} data-sentry-element="Script" data-sentry-source-file="index.tsx" />
      <div ref={paymentInputsRef} className={styles.paymentInputs}>
        <div className={styles.closeButton} onClick={onClose}></div>
        {selectedPaymentMethod?.label && <div className={styles.paymentLabel}>{selectedPaymentMethod.label}</div>}
        {selectedPaymentMethod?.infoText && <div className={styles.paymentInfoText}>{selectedPaymentMethod.infoText}</div>}

        <div id="div-hosted-tokenization" className={classNames(styles.paymentInputsWrap)}></div>

        {tokenizer && !successfulSubmit && <Button label={formatMessage({
        id: 'submit',
        defaultMessage: 'Abschicken'
      })} onClick={submitForm} size={'large'} />}
        {successfulSubmit && <>
            <div className={styles.paymentInfoText}>
              <CustomMarkdown text={creditCardAuthorizedInfo} />
            </div>
            <Button label={formatMessage({
          id: 'next',
          defaultMessage: 'Weiter'
        })} onClick={onConfirm} size={'large'} />
          </>}
      </div>
    </>;
};