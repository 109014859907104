import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { defaultCheckoutData } from '@frontastic-engbers/helpers/constants/checkout';
import { useShippingCosts } from '@frontastic-engbers/helpers/hooks/useShippingCosts';
import { useShippingTime } from '@frontastic-engbers/helpers/hooks/useShippingTime';
import { useAccount } from '@frontastic-engbers/lib';
import { useShippingProvider } from '@frontastic-engbers/lib/actions/config';
import { useCart } from '@frontastic-engbers/lib/provider';
import { getTaxedCountries } from '@frontastic-engbers/helpers/utils/getTaxedCountries';
import { Address } from '@frontastic-engbers/types/account/Address';
import { ShippingCosts } from '@frontastic-engbers/types/cart/ShippingInfo';
import { ShippingMethod } from '@frontastic-engbers/types/cart/ShippingMethod';
import { ProjectSettings } from '@frontastic-engbers/types/ProjectSettings';
import { CheckoutData, CustomerData, CustomerOrigin, NewsletterCheckbox, Solvency } from '@frontastic-engbers/types/engbers-custom';
interface CheckoutDataContextType {
  data: CheckoutData;
  setData: React.Dispatch<React.SetStateAction<CheckoutData>>;
  updateFormInput: (propName: string, newValue: string | boolean | Address | CustomerData | NewsletterCheckbox | Solvency) => void;
  customerOrigin: CustomerOrigin;
  customerOriginDetermined: boolean;
  availableCountryOptions: string[];
  shippingMethods: ShippingMethod[];
  shippingTime: string;
  shippingCosts: ShippingCosts;
}
export const CheckoutDataContext = createContext<CheckoutDataContextType>({
  data: undefined,
  setData() {},
  updateFormInput() {},
  customerOrigin: {
    isGuest: false,
    isNew: false
  },
  customerOriginDetermined: false,
  availableCountryOptions: [],
  shippingMethods: [],
  shippingTime: '',
  shippingCosts: {
    billing: '',
    shipping: '',
    store: '',
    packstation: ''
  }
});
const CheckoutDataProvider = ({
  children
}: React.PropsWithChildren<any>) => {
  const {
    loggedIn
  } = useAccount();
  const {
    data: cart,
    getProjectSettings,
    getShippingMethods
  } = useCart();
  const {
    shippingProvider,
    fallbackShippingCostsDE,
    fallbackShippingCostsEU
  } = useShippingProvider();
  const router = useRouter();
  const [data, setData] = useState<CheckoutData>(defaultCheckoutData);
  const [customerOrigin, setCustomerOrigin] = useState<CustomerOrigin>({
    isGuest: false,
    isNew: false
  });
  const [projectSettings, setProjectSettings] = useState<ProjectSettings>(null);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>(null);
  const [availableCountryOptions, setAvailableCountryOptions] = useState<string[]>(null);
  const customerOriginDetermined = useRef<boolean>(false);
  const urlQueryArg = router.query;
  const isGuest = !loggedIn && urlQueryArg.isGuest === 'true';
  const isNew = !loggedIn && urlQueryArg.isNew === 'true';
  useEffect(() => {
    const customerOrigin = {
      isGuest: isGuest,
      isNew: !loggedIn && (isNew || !isGuest && !loggedIn)
    };
    if (loggedIn) {
      customerOrigin.isGuest = false;
      customerOrigin.isNew = false;
    }
    setCustomerOrigin(customerOrigin);
    customerOriginDetermined.current = true;
  }, [loggedIn]);
  const updateFormInput = (propName: string, newValue: string | boolean | Address | CustomerData | NewsletterCheckbox | Solvency) => {
    setData(prevState => ({
      ...prevState,
      [propName]: newValue
    }));
  };
  useEffect(() => {
    getShippingMethods().then(data => {
      setShippingMethods(data);
    });
    getProjectSettings().then(data => {
      setProjectSettings(data);
    });
  }, [getProjectSettings, getShippingMethods]);
  useEffect(() => {
    if (shippingMethods && projectSettings) {
      const countries = getTaxedCountries(shippingMethods, projectSettings?.countries);
      setAvailableCountryOptions(countries);
    }
  }, [shippingMethods, projectSettings]);
  const getFallbackShippingCosts = (country?: string) => !country || country.toLowerCase() === 'de' ? fallbackShippingCostsDE : fallbackShippingCostsEU;
  const shippingCosts = useShippingCosts(cart, data, shippingMethods, getFallbackShippingCosts);
  const shippingTime = useShippingTime(cart, shippingProvider, shippingMethods);
  const value = useMemo(() => ({
    data,
    setData,
    // Todo: check if we can work with updateFormInput in billing address component instead
    updateFormInput,
    customerOrigin,
    customerOriginDetermined: customerOriginDetermined.current,
    availableCountryOptions,
    shippingMethods,
    shippingTime,
    shippingCosts
  }), [data, customerOrigin, availableCountryOptions, shippingMethods, shippingTime, shippingCosts]);
  return <CheckoutDataContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="CheckoutDataProvider" data-sentry-source-file="CheckoutDataProvider.tsx">{children}</CheckoutDataContext.Provider>;
};
export default CheckoutDataProvider;
export const useCheckout = () => useContext(CheckoutDataContext);