export function debounce<T extends (...args: any[]) => Promise<any>>(func: T, delay: number) {
  // eslint-disable-next-line no-undef
  let timer: NodeJS.Timeout;

  return (...args: Parameters<T>): Promise<any> =>
    new Promise((resolve) => {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const result = await func(...args);
        resolve(result);
      }, delay);
    });
}
