import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { CurrentRefinementsConnectorParamsRefinement } from 'instantsearch.js/es/connectors/current-refinements/connectCurrentRefinements';
import { useClearRefinements, useCurrentRefinements, useInstantSearch } from 'react-instantsearch-core';
import { refinementRemovedEventName, refinementsClearedEventName } from './constants';
import { ProductListContextShape, RefinementRemovedEvent } from './types';
import { FacetConfiguration } from '../types';
export const ProductListContext = createContext<ProductListContextShape>({
  facetsConfiguration: {},
  applyFilters() {},
  updateSelectedFacets() {},
  updateFacetsConfiguration() {},
  removeRefinement() {},
  removeAllRefinements() {}
});
const ProductListProvider = ({
  children
}: React.PropsWithChildren<any>) => {
  const [facetsConfiguration, setFacetsConfiguration] = useState<Record<string, FacetConfiguration>>({});
  const [selectedFacetValues, setSelectedFacetValues] = useState<Record<string, string[] | undefined>>({});
  const {
    items,
    refine
  } = useCurrentRefinements();
  const {
    refine: clearAllRefinements
  } = useClearRefinements();
  const {
    setIndexUiState
  } = useInstantSearch();
  const applyFilters = useCallback((facets?: Record<string, string[]>) => {
    if (Object.keys(selectedFacetValues).length > 0) {
      setIndexUiState(previousIndexUiState => ({
        ...previousIndexUiState,
        refinementList: {
          ...previousIndexUiState.refinementList,
          ...(facets ?? selectedFacetValues)
        }
      }));
      setSelectedFacetValues({});
    }
  }, [selectedFacetValues, setIndexUiState]);
  const updateSelectedFacets = useCallback((facets: Record<string, string[]>) => {
    setSelectedFacetValues(facets);
  }, []);
  const updateFacetsConfiguration = useCallback((newFacetsConfiguration: Record<string, FacetConfiguration>) => {
    setFacetsConfiguration(newFacetsConfiguration);
  }, []);
  const removeNumericRefinement = useCallback((refinement: CurrentRefinementsConnectorParamsRefinement) => {
    items.forEach(item => {
      item.refinements.forEach(r => {
        if (r.attribute === refinement.attribute) {
          refine(r);
        }
      });
    });
  }, [items, refine]);
  const removeRefinement = useCallback((refinement: CurrentRefinementsConnectorParamsRefinement) => {
    if (refinement.type === 'numeric') {
      removeNumericRefinement(refinement);
    } else {
      refine(refinement);
    }
    window.dispatchEvent(new CustomEvent<RefinementRemovedEvent>(refinementRemovedEventName, {
      detail: {
        attribute: refinement.attribute
      }
    }));
  }, [refine, removeNumericRefinement]);
  const removeAllRefinements = useCallback(() => {
    clearAllRefinements();
    window.dispatchEvent(new CustomEvent(refinementsClearedEventName));
  }, [clearAllRefinements]);
  const value = useMemo(() => ({
    facetsConfiguration,
    applyFilters,
    updateSelectedFacets,
    updateFacetsConfiguration,
    removeRefinement,
    removeAllRefinements
  }), [facetsConfiguration, applyFilters, updateSelectedFacets, updateFacetsConfiguration, removeRefinement, removeAllRefinements]);
  return <ProductListContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="ProductListProvider" data-sentry-source-file="index.tsx">{children}</ProductListContext.Provider>;
};
export default ProductListProvider;
export const useProductList = () => useContext(ProductListContext);