import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { isCheckoutSubmittedState } from '@frontastic-engbers/lib/state/checkoutErrors/atoms';
import { Block, InputCheckbox, InputText, Markdown, PasswordCheckList } from '@engbers/components';
import getBlockStyle from '@frontastic-engbers/helpers/utils/getBlockStyle';
import { EmailValidatorHelper } from '@frontastic-engbers/helpers/emailValidatorHelper';
import { PasswordCheckListHelper } from '@frontastic-engbers/helpers/passwordCheckListHelper';
import { CheckoutErrorsType } from '@frontastic-engbers/types/engbers-custom';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import styles from '../../checkout.module.scss';
import { useCheckout } from '../../context/CheckoutDataProvider';
type ValidationErrors = {
  email: boolean;
  emailsMatch: boolean;
  password: boolean;
  passwordMatch: boolean;
  agbAccepted: boolean;
  postSubmit: boolean;
  cardAgbAccepted: boolean;
};
interface IAddressExtension {
  labels: {
    [name: string]: string;
  };
  isMinWidthMedium: boolean;
  setCheckoutErrors: React.Dispatch<React.SetStateAction<CheckoutErrorsType>>;
}
export const AddressExtension: React.FC<IAddressExtension> = ({
  labels,
  isMinWidthMedium,
  setCheckoutErrors
}) => {
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const {
    data: checkoutData,
    updateFormInput,
    customerOrigin
  } = useCheckout();
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});
  const [passwordErrors, setPasswordErrors] = useState<{
    valid: boolean;
    message: string;
  } | null>(null);
  const [isPassInputFocused, setIsPassInputFocused] = useState<boolean>(false);
  const [emailErrors, setEmailErrors] = useState<string | null>(null);
  const [isCheckoutSubmitted, setIsCheckoutSubmitted] = useRecoilState(isCheckoutSubmittedState);
  const customerData = checkoutData.customerData;
  const updateCustomerData = (propName: string, newValue: string | boolean) => {
    updateFormInput('customerData', {
      ...checkoutData.customerData,
      [propName]: newValue
    });
  };
  const handleCustomerDataChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    updateCustomerData(e.target.name, e.target.value);
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateCustomerData(e.target.name, e.target.checked);
    setErrors(errors => ({
      ...errors,
      [e.target.name]: !e.target.checked
    }));
  };
  const validateEmail = async (value: string) => {
    const ruleDefinitions: string | null = await EmailValidatorHelper.ruleDefinitions(value, true);
    if (!ruleDefinitions) {
      setEmailErrors(null);
      setErrors(prev => ({
        ...prev,
        email: false,
        emailsMatch: value !== customerData.emailRepeat
      }));
      return;
    }
    setEmailErrors(ruleDefinitions);
    setErrors(prev => ({
      ...prev,
      email: true,
      emailsMatch: value !== customerData.emailRepeat
    }));
  };
  const validatePassword = (value: string) => {
    const ruleDefinitions: any = PasswordCheckListHelper.ruleDefinitions(value, 6);
    const rulesKeys: string[] = Object.keys(ruleDefinitions).filter(key => !ruleDefinitions[key].valid);
    rulesKeys.forEach(key => {
      if (!ruleDefinitions[key].valid) {
        setErrors(prev => ({
          ...prev,
          password: !ruleDefinitions[key].valid,
          passwordMatch: customerOrigin.isNew ? value !== customerData.passwordRepeat : false
        }));
        setPasswordErrors(ruleDefinitions[key]);
      }
    });
    if (!rulesKeys.length) {
      setErrors(prev => ({
        ...prev,
        password: customerOrigin.isNew ? !value : false,
        passwordMatch: customerOrigin.isNew ? value !== customerData.passwordRepeat : false
      }));
      setPasswordErrors({
        valid: true,
        message: ''
      });
    }
  };
  useEffect(() => {
    if (isCheckoutSubmitted) {
      const validate = async () => {
        await validateEmail(customerData.email);
        !customerOrigin.isGuest && validatePassword(customerData.password);
      };
      setErrors({
        agbAccepted: customerOrigin.isGuest ? !customerData.agbAccepted : false,
        postSubmit: !customerData.postSubmit,
        cardAgbAccepted: customerOrigin.isNew ? !customerData.cardAgbAccepted : false
      });
      validate();
      setIsCheckoutSubmitted(false);
    }
  }, [isCheckoutSubmitted]);
  useEffect(() => {
    const filteredErrors = Object.entries(errors).filter(([key, value]) => value && key !== 'postSubmit' && key !== (customerOrigin.isNew ? 'agbAccepted' : 'cardAgbAccepted')).length;
    if (!setCheckoutErrors) {
      return;
    }
    if (filteredErrors || emailErrors !== null || passwordErrors && !passwordErrors.valid) {
      setCheckoutErrors(state => ({
        ...state,
        billingAddress: {
          isValid: false,
          section: state?.billingAddress.section
        }
      }));
    } else {
      setCheckoutErrors(state => ({
        ...state,
        billingAddress: {
          isValid: true,
          section: state?.billingAddress.section
        }
      }));
    }
  }, [errors, emailErrors, passwordErrors]);
  return <div data-sentry-component="AddressExtension" data-sentry-source-file="index.tsx">
      {customerOrigin.isGuest || customerOrigin.isNew ? <>
          <Block customStyle={getBlockStyle(true, isMinWidthMedium)} marginBottom={4}>
            <InputText id="email" name="email" type="email" autoComplete="email" placeholder={labels.labelEmail ? labels.labelEmail : formatMessage({
          id: 'email'
        })} required errorMessage={!emailErrors ? undefined : emailErrors === 'empty' ? formatMessage({
          id: 'email'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : emailErrors === 'pattern' ? labels.invalidMailFormat || formatMessage({
          id: 'emailWrongFormat'
        }) : formatErrorMessage({
          id: 'emailInUse'
        })} errorClassName={emailErrors === 'used' ? 'used-email-error' : undefined} onChange={e => {
          handleCustomerDataChange(e);
          validateEmail(e.target.value);
        }} onBlur={e => validateEmail(e.target.value)} value={customerData.email} wrapperCustomStyle={{
          width: '100%'
        }} successValidation hasError={errors.email} />
          </Block>
          <Block customStyle={getBlockStyle(false, isMinWidthMedium)} marginBottom={4}>
            <InputText id="email_repeat" name="emailRepeat" placeholder={labels.labelEmailRepeat ? labels.labelEmailRepeat : formatMessage({
          id: 'emailRepeat'
        })} type="email" autoComplete="email" required errorMessage={errors.emailsMatch ? formatErrorMessage({
          id: 'emailMustMatch'
        }) : undefined} onChange={e => {
          handleCustomerDataChange(e);
          setErrors({
            ...errors,
            emailsMatch: customerData.email !== e.target.value
          });
        }} onBlur={() => setErrors({
          ...errors,
          emailsMatch: customerData.email !== customerData.emailRepeat
        })} value={customerData.emailRepeat} wrapperCustomStyle={{
          width: '100%'
        }} onPaste={e => e.preventDefault()} successValidation hasError={errors.emailsMatch} />
          </Block>
        </> : null}
      {customerOrigin.isGuest ? <Block marginBottom={4}>
          <InputCheckbox id="accept_engbers_agb" name="agbAccepted" label={labels.labelAgb ? <Markdown linkToNewTab text={labels.labelAgb} className={styles.labelHasNoMargin} /> : formatMessage({
        id: 'agbAcception'
      })} onChange={handleCheckboxChange} errorMessage={errors.agbAccepted ? formatErrorMessage({
        id: 'mandatory'
      }) : undefined} checked={checkoutData.customerData.agbAccepted} hasHoverEffect={true} />
        </Block> : null}
      {customerOrigin.isNew ? <>
          <Block customStyle={getBlockStyle(true, isMinWidthMedium)} marginBottom={4}>
            <InputText id="password" name="password" type="password" autoComplete="password" onFocus={() => setIsPassInputFocused(true)} placeholder={labels.labelPassword ? labels.labelPassword : formatMessage({
          id: 'password'
        })} required errorMessage={passwordErrors && !passwordErrors.valid ? passwordErrors.message : undefined} onChange={e => {
          handleCustomerDataChange(e);
          validatePassword(e.target.value);
        }} onBlur={e => {
          setIsPassInputFocused(false);
          validatePassword(e.target.value);
        }} value={customerData.password} wrapperCustomStyle={{
          width: '100%'
        }} successValidation hasError={errors.password} />
            {isPassInputFocused ? <Block marginTop={2}>
                <PasswordCheckList rules={['minLength', 'lowercase', 'capital', 'number', 'specialChar']} minLength={6} value={customerData.password} />
              </Block> : null}
          </Block>
          <Block customStyle={getBlockStyle(false, isMinWidthMedium)} marginBottom={4}>
            <InputText id="password_repeat" name="passwordRepeat" placeholder={labels.labelPasswordRepeat ? labels.labelPasswordRepeat : formatMessage({
          id: 'passwordRepeat'
        })} type="password" autoComplete="password" required errorMessage={errors.passwordMatch ? formatErrorMessage({
          id: 'password.noMatch'
        }) : undefined} onChange={e => {
          handleCustomerDataChange(e);
          setErrors({
            ...errors,
            passwordMatch: customerData.password !== e.target.value
          });
        }} onBlur={() => setErrors({
          ...errors,
          passwordMatch: customerData.password !== customerData.passwordRepeat
        })} value={checkoutData.customerData.passwordRepeat} wrapperCustomStyle={{
          width: '100%'
        }} onPaste={e => e.preventDefault()} successValidation hasError={errors.passwordMatch} />
          </Block>
          <Block marginBottom={4}>
            <InputCheckbox id="accept_post_submit" name="postSubmit" label={labels.labelPostActions ? <Markdown text={labels.labelPostActions} className={styles.labelHasNoMargin} /> : formatMessage({
          id: 'postAcception'
        })} onChange={handleCheckboxChange} checked={checkoutData.customerData.postSubmit} hasHoverEffect={true} />
          </Block>
          <Block marginBottom={4}>
            <InputCheckbox id="accept_engbers_card_agb" name="cardAgbAccepted" label={labels.labelCardAgb ? <Markdown linkToNewTab text={labels.labelCardAgb} className={styles.labelHasNoMargin} /> : formatMessage({
          id: 'cardAgbAcception'
        })} onChange={handleCheckboxChange} errorMessage={errors.cardAgbAccepted ? formatErrorMessage({
          id: 'mandatory'
        }) : undefined} checked={checkoutData.customerData.cardAgbAccepted} hasHoverEffect={true} />
          </Block>
        </> : null}
    </div>;
};