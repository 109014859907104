import React, { useCallback, useEffect, useState } from 'react';
import { useAccount } from '@frontastic-engbers/lib';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { validateDateFormat } from '@frontastic-engbers/helpers/utils/validateDateFormat';
import { validatePostalCode } from '@frontastic-engbers/helpers/utils/validatePostalCode';
import { validateStreetName } from '@frontastic-engbers/helpers/utils/validateStreetName';
import { postcodeValidator } from 'postcode-validator';
import { IFTLinkReference, IFTPageFolderReference, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { Block, Button, CountrySelect, InputDate, InputRadioGroup, InputText, LoadingIndicatorInline, MyAccountHeadline } from '@engbers/components';
import { Account, AccountUpdateBody } from '@frontastic-engbers/types/account/Account';
import { MyDataChangeEmail } from './changeEmail';
import { MyDataChangePassword } from './changePassword';
import styles from './my-data.module.scss';
import { containsExcludedChars } from '@frontastic-engbers/helpers/utils/webserviceExcludedChars';
type AccountMyDataProps = {
  props: {
    myDataHeadline: string;
    textSize?: number;
    myDataIcon: MediaType;
    myDataSubheadline: string;
    myDataChangeEmailTitle: string;
    myDataAccessDataTitle: string;
    myDataBillingAddressTitle: string;
    myDataEmailTooltip: string;
    myDataBirthdayMarkdown: string;
    doiNotificationLink?: IFTLinkReference | IFTPageFolderReference;
  };
};
type StreetNamePostalCodeErrors = {
  streetName: boolean;
  postalCode: boolean;
  postalCodeCountryNoMatch: boolean;
};
type MyDataFormState = AccountUpdateBody;
const accountToFormData = (account: Account): MyDataFormState => {
  const address = account.addresses && account.addresses[0];
  return {
    email: account.email,
    salutation: account.salutation,
    firstName: account.firstName ?? '',
    lastName: account.lastName ?? '',
    addressStreetName: address?.streetName ?? '',
    addressStreetNumber: address?.streetNumber ?? '',
    addressPostalCode: address?.postalCode ?? '',
    addressAdditionalAddress: address?.additionalAddressInfo ?? '',
    addressCity: address?.city ?? '',
    addressCountry: address?.country ?? '',
    phone: address?.phone ?? '',
    birthdayYear: String(account.birthday?.getFullYear()) ?? '2023',
    birthdayMonth: account.birthday?.getMonth() ? String('0' + (account.birthday?.getMonth() + 1)).slice(-2) : '01',
    birthdayDay: account.birthday?.getDate() ? String('0' + account.birthday?.getDate()).slice(-2) : '01'
  };
};
const validate = (data: MyDataFormState, country: string = null) => {
  const isCountryIreland = country ? country.toLowerCase() === 'ie' : data.addressCountry?.toLowerCase() === 'ie';
  return {
    salutation: data.salutation !== '',
    firstName: data.firstName !== '',
    lastName: data.lastName !== '',
    addressStreetName: data?.addressStreetName !== '',
    addressStreetNumber: data?.addressStreetNumber !== '',
    addressPostalCode: isCountryIreland || data?.addressPostalCode !== '',
    addressCity: data?.addressCity !== '',
    addressCountry: !!country || data?.addressCountry !== '',
    birthdayYear: !!data.birthdayYear && validateDateFormat(data.birthdayDay.toString(), data.birthdayMonth.toString(), data.birthdayYear.toString()),
    birthdayMonth: !!data.birthdayMonth && validateDateFormat(data.birthdayDay.toString(), data.birthdayMonth.toString(), data.birthdayYear.toString()),
    birthdayDay: !!data.birthdayDay && validateDateFormat(data.birthdayDay.toString(), data.birthdayMonth.toString(), data.birthdayYear.toString())
  };
};
const specialCharValidate = (data: MyDataFormState) => {
  return {
    firstName: containsExcludedChars(data.firstName),
    lastName: containsExcludedChars(data.lastName),
    addressStreetName: containsExcludedChars(data.addressStreetName),
    addressStreetNumber: containsExcludedChars(data.addressStreetNumber),
    addressAdditionalAddress: containsExcludedChars(data.addressAdditionalAddress),
    addressPostalCode: containsExcludedChars(data.addressPostalCode),
    addressCity: containsExcludedChars(data.addressCity)
  };
};
export const AccountMyData: React.FC<AccountMyDataProps> = ({
  props
}) => {
  const {
    myDataHeadline,
    textSize,
    myDataIcon,
    myDataSubheadline,
    myDataChangeEmailTitle,
    myDataAccessDataTitle,
    myDataBillingAddressTitle,
    myDataEmailTooltip,
    myDataBirthdayMarkdown,
    doiNotificationLink
  } = props;
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const {
    account,
    update,
    loggedIn,
    loaded,
    updateAccountWithWebservice
  } = useAccount();
  const {
    pushNotification
  } = useToastNotificationsActions();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [data, setData] = useState<MyDataFormState>(accountToFormData(account));
  const [streetNamePostalCodeErrors, setStreetNamePostalCodeErrors] = useState<Partial<StreetNamePostalCodeErrors>>({
    postalCodeCountryNoMatch: false
  });
  const [errors, setErrors] = useState({
    salutation: true,
    firstName: true,
    lastName: true,
    addressStreetName: true,
    addressStreetNumber: true,
    addressPostalCode: true,
    addressCity: true,
    addressCountry: true,
    birthdayYear: true,
    birthdayMonth: true,
    birthdayDay: true
  });
  const [specialCharErrors, setSpecialCharErrors] = useState({
    firstName: false,
    lastName: false,
    addressStreetName: false,
    addressStreetNumber: false,
    addressAdditionalAddress: false,
    addressPostalCode: false,
    addressCity: false
  });
  useEffect(() => {
    if (!loaded || !loggedIn) {
      return;
    }
    setData(accountToFormData(account));
  }, [account, loggedIn, loaded]);
  useEffect(() => {
    const updateAccount = async () => {
      await updateAccountWithWebservice(account);
      setDataLoaded(true);
    };
    updateAccount();
  }, []);
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  }, [setData]);
  const validatePostalCodeField = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isCountryGermany = data.addressCountry.toLowerCase() === 'de';
    if (isCountryGermany) {
      setStreetNamePostalCodeErrors(state => ({
        ...state,
        postalCode: !validatePostalCode(e.target.value)
      }));
    }
  };
  const validateCountryToPostCode = (countryCode: string, postCode: string) => {
    setStreetNamePostalCodeErrors(state => ({
      ...state,
      postalCodeCountryNoMatch: !postcodeValidator(postCode, countryCode)
    }));
  };
  const handleSubmit = useCallback(async e => {
    e.preventDefault();
    const err = validate(data);
    const specialChars = specialCharValidate(data);
    const isCountryGermany = data.addressCountry.toLowerCase() === 'de';
    setErrors(err);
    setSpecialCharErrors(specialChars);
    setStreetNamePostalCodeErrors({
      streetName: !validateStreetName(data.addressStreetName),
      postalCode: isCountryGermany ? !validatePostalCode(data.addressPostalCode) : false
    });
    if (Object.values(err).some(error => !error) || Object.values(specialChars).includes(true) || !validateStreetName(data.addressStreetName) || isCountryGermany && !validatePostalCode(data.addressPostalCode)) {
      return;
    }
    setLoading(true);
    try {
      await update(data);
      pushNotification(formatAccountMessage({
        id: 'account.data.change',
        defaultMessage: 'Your data has been successfully updated.'
      }), 'success');
    } catch (e) {
      setErrors(prev => ({
        ...prev,
        generalError: formatErrorMessage({
          id: 'wentWrong',
          defaultMessage: 'Sorry. Something went wrong..'
        })
      }));
    }
    setLoading(false);
  }, [setLoading, setErrors, data]);
  if (!data) {
    return null;
  }
  return <div data-sentry-component="AccountMyData" data-sentry-source-file="index.tsx">
      <MyAccountHeadline headlineText={myDataHeadline} textSize={textSize} iconCustom="User" iconMedia={myDataIcon} uppercase data-sentry-element="MyAccountHeadline" data-sentry-source-file="index.tsx" />
      <Block marginBottom={6} marginTop={8} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <div>{myDataSubheadline}</div>
      </Block>

      <MyDataChangeEmail currentEmail={data.email} myDataEmailTooltip={myDataEmailTooltip} myDataChangeEmailTitle={myDataChangeEmailTitle} doiNotificationLink={doiNotificationLink} data-sentry-element="MyDataChangeEmail" data-sentry-source-file="index.tsx" />

      <MyDataChangePassword currentEmail={data.email} myDataAccessDataTitle={myDataAccessDataTitle} data-sentry-element="MyDataChangePassword" data-sentry-source-file="index.tsx" />

      {dataLoaded ? <form onSubmit={handleSubmit} className={styles.form}>
          <Block marginTop={8} marginBottom={4} paddingTop={8} customStyle={{
        borderTop: '1px dotted #dadada'
      }}>
            <h3>{myDataBillingAddressTitle}</h3>
          </Block>
          <Block marginBottom={4}>
            <InputRadioGroup onChange={id => {
          setData(prev => ({
            ...prev,
            salutation: id as any
          }));
          if (id) {
            setErrors({
              ...errors,
              salutation: true
            });
          }
        }} options={[{
          label: 'Herr',
          id: 'MR'
        }, {
          label: 'Frau',
          id: 'MRS'
        }]} activeId={data.salutation} errorMessage={!errors.salutation ? formatMessage({
          id: 'salutation'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} />
          </Block>
          <Block marginBottom={4}>
            <InputText placeholder={formatMessage({
          id: 'firstName'
        })} name="firstName" type="text" autoComplete="given-name" required value={data.firstName} onChange={handleChange} onBlur={() => {
          setErrors(state => ({
            ...state,
            firstName: data.firstName !== ''
          }));
          setSpecialCharErrors(state => ({
            ...state,
            firstName: containsExcludedChars(data.firstName)
          }));
        }} errorMessage={specialCharErrors.firstName ? formatErrorMessage({
          id: 'specialChars'
        }) : !errors.firstName ? formatMessage({
          id: 'firstName'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} />
          </Block>
          <Block marginBottom={4}>
            <InputText id="last_name" name="lastName" type="text" placeholder={formatMessage({
          id: 'lastName'
        })} autoComplete="family-name" required value={data.lastName} onChange={handleChange} onBlur={() => {
          setErrors(state => ({
            ...state,
            lastName: data.lastName !== ''
          }));
          setSpecialCharErrors(state => ({
            ...state,
            lastName: containsExcludedChars(data.lastName)
          }));
        }} errorMessage={specialCharErrors.lastName ? formatErrorMessage({
          id: 'specialChars'
        }) : !errors.lastName ? formatMessage({
          id: 'lastName'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} />
          </Block>
          <Block marginBottom={4}>
            <div className={styles.rowSplit}>
              <Block maxWidth="70%" customStyle={{
            width: '100%'
          }}>
                <InputText id="address_street_name" name="addressStreetName" type="text" autoComplete="" required value={data.addressStreetName} placeholder={formatMessage({
              id: 'street.name'
            })} onBlur={e => {
              setErrors(state => ({
                ...state,
                addressStreetName: data.addressStreetName !== ''
              }));
              setSpecialCharErrors(state => ({
                ...state,
                addressStreetName: containsExcludedChars(data.addressStreetName)
              }));
              setStreetNamePostalCodeErrors(state => ({
                ...state,
                streetName: !validateStreetName(e.target.value)
              }));
            }} onChange={e => {
              handleChange(e);
              setStreetNamePostalCodeErrors(state => ({
                ...state,
                streetName: !validateStreetName(e.target.value)
              }));
            }} errorMessage={specialCharErrors.addressStreetName ? formatErrorMessage({
              id: 'specialChars'
            }) : !errors.addressStreetName ? formatMessage({
              id: 'street.name'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : streetNamePostalCodeErrors.streetName ? formatErrorMessage({
              id: 'streetNameCharacters',
              defaultMessage: 'Straße darf keine Zahlen enthalten!'
            }) : undefined} />
              </Block>
              <Block maxWidth="30%" customStyle={{
            width: '100%'
          }}>
                <InputText id="address_street_number" name="addressStreetNumber" type="text" autoComplete="" required placeholder={formatMessage({
              id: 'street.number'
            })} value={data.addressStreetNumber} onChange={handleChange} onBlur={() => {
              setErrors(state => ({
                ...state,
                addressStreetNumber: data.addressStreetNumber !== ''
              }));
              setSpecialCharErrors(state => ({
                ...state,
                addressStreetNumber: containsExcludedChars(data.addressStreetNumber)
              }));
            }} errorMessage={specialCharErrors.addressStreetNumber ? formatErrorMessage({
              id: 'specialChars'
            }) : !errors.addressStreetNumber ? formatMessage({
              id: 'street.number'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} />
              </Block>
            </div>
          </Block>
          <Block marginBottom={4}>
            <InputText id="address_additional_address" name="addressAdditionalAddress" type="text" autoComplete="" placeholder={formatMessage({
          id: 'addressSupplement'
        })} value={data.addressAdditionalAddress} onBlur={() => {
          setSpecialCharErrors(state => ({
            ...state,
            addressAdditionalAddress: containsExcludedChars(data.addressAdditionalAddress)
          }));
        }} errorMessage={specialCharErrors.addressAdditionalAddress ? formatErrorMessage({
          id: 'specialChars'
        }) : undefined} onChange={handleChange} />
          </Block>
          <Block marginBottom={4}>
            <div className={styles.rowSplit}>
              <Block maxWidth="30%" customStyle={{
            width: '100%'
          }}>
                <InputText id="address_zip" name="addressPostalCode" type="text" placeholder={formatMessage({
              id: 'zipCode'
            })} autoComplete="" required={data.addressCountry?.toLowerCase() !== 'ie'} value={data.addressPostalCode} onBlur={e => {
              const isCountryIreland = data.addressCountry?.toLowerCase() === 'ie';
              setErrors(state => ({
                ...state,
                addressPostalCode: isCountryIreland || data.addressPostalCode !== ''
              }));
              setSpecialCharErrors(state => ({
                ...state,
                addressPostalCode: containsExcludedChars(data.addressPostalCode)
              }));
              validatePostalCodeField(e);
              validateCountryToPostCode(data.addressCountry, e.target.value);
            }} onChange={e => {
              handleChange(e);
              validatePostalCodeField(e);
              validateCountryToPostCode(data.addressCountry, e.target.value);
            }} disableErrorIcon errorMessage={specialCharErrors.addressPostalCode ? formatErrorMessage({
              id: 'specialChars'
            }) : !errors.addressPostalCode ? formatMessage({
              id: 'zipCode'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : streetNamePostalCodeErrors.postalCodeCountryNoMatch ? streetNamePostalCodeErrors.postalCode ? formatErrorMessage({
              id: 'postalCodeLength',
              defaultMessage: 'PLZ muss in Deutschland 5 Zeichen lang sein'
            }) : formatErrorMessage({
              id: 'postalCodeValid',
              defaultMessage: 'Bitte geben Sie eine gültige PLZ ein'
            }) : undefined} />
              </Block>
              <Block maxWidth="70%" customStyle={{
            width: '100%'
          }}>
                <InputText id="address_city" name="addressCity" type="text" autoComplete="" placeholder={formatMessage({
              id: 'place'
            })} required value={data.addressCity} onChange={handleChange} onBlur={() => {
              setErrors(state => ({
                ...state,
                addressCity: data.addressCity !== ''
              }));
              setSpecialCharErrors(state => ({
                ...state,
                addressCity: containsExcludedChars(data.addressCity)
              }));
            }} errorMessage={specialCharErrors.addressCity ? formatErrorMessage({
              id: 'specialChars'
            }) : !errors.addressCity ? formatMessage({
              id: 'place'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : streetNamePostalCodeErrors.postalCodeCountryNoMatch ? '' : undefined} hasError={streetNamePostalCodeErrors.postalCodeCountryNoMatch} />
              </Block>
            </div>
          </Block>
          <Block marginBottom={6}>
            <CountrySelect id="address_country" placeholder={formatMessage({
          id: 'country'
        })} name="addressCountry" autoComplete="" required value={data.addressCountry} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const isCountryGermany = e.target.value.toLowerCase() === 'de';
          const isCountryIreland = e.target.value.toLowerCase() === 'ie';
          setData(prev => ({
            ...prev,
            addressCountry: e.target.value
          }));
          setErrors({
            ...errors,
            addressPostalCode: isCountryIreland || data.addressPostalCode !== ''
          });
          validateCountryToPostCode(e.target.value, data.addressPostalCode);
          setStreetNamePostalCodeErrors(state => ({
            ...state,
            streetName: !validateStreetName(data.addressStreetName),
            postalCode: isCountryGermany ? !validatePostalCode(data.addressPostalCode) : false
          }));
        }} />
          </Block>
          <Block marginBottom={8}>
            <div>Geburtsdatum</div>
            <InputDate label={formatMessage({
          id: 'birthDate'
        })} disableValidatioOnBlur onChange={value => setData(prev => ({
          ...prev,
          ...value
        }))} birthdayDay={data.birthdayDay || ''} birthdayMonth={data.birthdayMonth || ''} birthdayYear={data.birthdayYear || ''} required isFormatValid={valid => setErrors({
          ...errors,
          birthdayDay: valid,
          birthdayMonth: valid,
          birthdayYear: valid
        })} stillHasError={errors && (errors.birthdayDay === false || errors.birthdayMonth === false || errors.birthdayYear === false)} info={myDataBirthdayMarkdown} useInfoModal />
          </Block>
          <Block customStyle={{
        marginLeft: 'auto'
      }} maxWidth="fit-content">
            <Button buttonType="submit" size="large" label={formatMessage({
          id: 'save.bold'
        })} isLoading={loading} customStyle={{
          paddingLeft: '30px',
          paddingRight: '30px'
        }} onClick={handleSubmit} disabled={Object.values(errors).some(error => !error) || Object.values(specialCharErrors).includes(true) || !validateStreetName(data.addressStreetName) || data.addressCountry.toLowerCase() === 'de' && !validatePostalCode(data.addressPostalCode)} />
          </Block>
        </form> : <LoadingIndicatorInline color={'blue'} />}
    </div>;
};