import React from 'react';
import { RaffleFormData } from '@frontastic-engbers/types/engbers-custom';
import { IQuizContent, ValidationErrors } from './index';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { Block, InputCheckbox, InputText, Markdown } from '@engbers/components';
import styles from './raffle.module.scss';
interface IRaffleQuiz {
  formData: RaffleFormData;
  setFormData: React.Dispatch<React.SetStateAction<RaffleFormData>>;
  errors: Partial<ValidationErrors>;
  setErrors: React.Dispatch<React.SetStateAction<Partial<ValidationErrors>>>;
  initialSend: boolean;
  quiz: IQuizContent;
  isMobile?: boolean;
}
export const RaffleQuiz: React.FC<IRaffleQuiz> = ({
  formData,
  setFormData,
  errors,
  setErrors,
  initialSend,
  quiz,
  isMobile = false
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const handleOnBlur = () => {
    setErrors(prevErrors => ({
      ...prevErrors,
      quizAnswer: quiz?.answersType !== 'checkbox' ? formData.raffleAnswer1 !== '' : formData.raffleAnswer1 !== '' || formData.raffleAnswer2 !== '' || formData.raffleAnswer3 !== ''
    }));
  };
  return <Block marginBottom={12} data-sentry-element="Block" data-sentry-component="RaffleQuiz" data-sentry-source-file="raffleQuiz.tsx">
      <Markdown text={quiz.quizQuestion} data-sentry-element="Markdown" data-sentry-source-file="raffleQuiz.tsx" />
      <Block data-sentry-element="Block" data-sentry-source-file="raffleQuiz.tsx">
        {quiz.answersType === 'radio' && quiz.quizAnswers?.length ? quiz.quizAnswers.map((answer, i) => <Block key={`${answer.answerValue}-${i}`} marginBottom={2} className="input-radio-group">
              <label className={styles.radioInputLabel}>
                <input type="radio" id={`quiz-answer-${i}`} name="raffleAnswer1" value={answer.answerValue} onChange={e => setFormData({
            ...formData,
            [e.target.name]: e.target.value
          })} onBlur={handleOnBlur} />
                {answer.answerLabel}
              </label>
            </Block>) : quiz.answersType === 'checkbox' && quiz.quizAnswers?.length ? quiz.quizAnswers.map((answer, i) => <Block marginBottom={3} key={`${answer.answerValue}-${i}`}>
              <InputCheckbox id={`quiz-answer-${i}`} name={`raffleAnswer${i + 1}`} label={answer.answerLabel} onChange={e => setFormData({
          ...formData,
          [e.target.name]: e.target.checked ? answer.answerValue : ''
        })} onBlur={handleOnBlur} style="white" hasHoverEffect />
            </Block>) : <InputText style={isMobile ? 'grey' : 'white'} name="raffleAnswer1" type="text" value={formData.raffleAnswer1} onChange={e => setFormData({
        ...formData,
        [e.target.name]: e.target.value
      })} onBlur={handleOnBlur} required placeholder={isMobile ? formatMessage({
        id: 'yourAnswer',
        defaultMessage: 'Ihre Antwort'
      }) : undefined} inputCustomStyle={!isMobile ? {
        height: '28px',
        padding: '0 8px'
      } : undefined} />}
      </Block>
      {!errors.quizAnswer && initialSend && <Markdown text={quiz.unselectedAnswerErrMsg} className={styles.quizErrorMsg} />}
    </Block>;
};