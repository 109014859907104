import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useRefinementList } from 'react-instantsearch';
import { IFacetProps } from './types';
import styles from './facets.module.scss';
import { handleFilterDataLayer } from '@frontastic-engbers/helpers/dataLayerHelper';
import ApplyFilterButton from './applyFilterButton';
import { useProductList } from '../../context';
const ColorFacet: React.FC<IFacetProps> = ({
  label,
  attribute,
  onApplyFilter,
  filterOnSelection = true,
  wrapper: Wrapper = React.Fragment,
  showItemCount = false
}) => {
  const {
    updateSelectedFacets
  } = useProductList();
  const {
    items,
    refine
  } = useRefinementList({
    attribute,
    limit: 100
  });
  const [selectedColors, setSelectedColors] = useState<Set<string>>(new Set());
  const updateSelectedColors = useCallback((term: string) => {
    if (filterOnSelection) {
      refine(term);
      handleFilterDataLayer(items, label, term);
    } else {
      setSelectedColors(prevSelectedColors => {
        const newSelection = new Set(prevSelectedColors);
        newSelection.has(term) ? newSelection.delete(term) : newSelection.add(term);
        return newSelection;
      });
    }
  }, [filterOnSelection, refine, items, label]);
  const selectedColorValues = useMemo(() => Array.from(selectedColors), [selectedColors]);
  useEffect(() => {
    const refinedColors = items.filter(item => item.isRefined).map(item => item.value);
    setSelectedColors(new Set(refinedColors));
  }, [items]);
  useEffect(() => {
    updateSelectedFacets({
      [attribute]: selectedColorValues
    });
  }, [updateSelectedFacets, attribute, selectedColorValues]);
  return <>
      <div className={styles.facetValueWrapper}>
        {items.map(term => <Wrapper key={term.value}>
            <div className={styles.termFacetValue}>
              <input className={styles.checkbox} onChange={() => updateSelectedColors(term.value)} id={term.value} name={term.value} checked={selectedColors.has(term.value)} type="checkbox" />
              <label className={styles.label} htmlFor={term.value}>
                <span className={classnames(styles.colorTile, term.value.toLowerCase())} />
                {term.label}{showItemCount && ` (${term.count.toString()})`}
              </label>
            </div>
          </Wrapper>)}
      </div>

      {!filterOnSelection && <ApplyFilterButton facets={{
      [attribute]: selectedColorValues
    }} onApplyFilters={() => onApplyFilter(attribute, label, selectedColorValues)} />}
    </>;
};
export default ColorFacet;