import React from 'react';
import { createQuerySuggestionsPlugin } from '@algolia/autocomplete-plugin-query-suggestions';
import { SearchClient } from 'algoliasearch/dist/algoliasearch-lite';
import { SuggestedSearch } from '../suggested-search';
const STORAGE_KEY = 'search_query_suggestions';
const getStoredSuggestions = () => {
  const storedData = localStorage.getItem(STORAGE_KEY);
  return storedData ? JSON.parse(storedData) : [];
};
const saveToLocalStorage = (suggestions: any[]) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(suggestions));
};
const updateStoredSuggestions = (newSuggestion: any) => {
  let suggestions = getStoredSuggestions();
  if (!suggestions.some(item => item.query === newSuggestion.query)) {
    suggestions = [newSuggestion, ...suggestions];
    saveToLocalStorage(suggestions);
  }
};
export const querySuggestionsPlugin = (searchClient: SearchClient, algoliaConfig: any, suggestIndexNamePrefix: string, onClick: () => void) => {
  return createQuerySuggestionsPlugin({
    searchClient,
    indexName: `${algoliaConfig.dataSource.indexName}${suggestIndexNamePrefix}`,
    getSearchParams() {
      return {
        hitsPerPage: 6
      };
    },
    transformSource({
      source
    }) {
      const storedSuggestions = getStoredSuggestions();
      const returnedTransformSource = {
        ...source,
        getItemUrl({
          item
        }) {
          return `/search/${item.query}`;
        },
        onSelect({
          setQuery,
          item,
          setIsOpen
        }) {
          setQuery(item.query);
          setIsOpen(false);
          onClick();
        },
        templates: {
          item({
            item
          }) {
            updateStoredSuggestions(item);
            return <SuggestedSearch value={item.query} />;
          }
        }
      };
      if (storedSuggestions.length) {
        returnedTransformSource.getItems = () => {
          return getStoredSuggestions();
        };
      }
      return returnedTransformSource;
    }
  });
};