import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Link from 'next/link';
import { FullPageWidthWrapper } from '@engbers/components/online-shops/full-page-width-wrapper';
import useI18n from '@frontastic-engbers/helpers/hooks/useI18n';
import { PageFolderTree } from '@frontastic-engbers/types/engbers-custom';
import { PageFolder } from '@frontastic-engbers/lib/lib/types';
import styles from './category-slider.module.scss';
export type Props = {
  navTree: PageFolderTree;
  pageFolder: PageFolder;
};
const filterActive = (c: PageFolderTree) => c && !c.configuration.hideInNavigation;
const findCategories = (tree: PageFolderTree, id: string): PageFolderTree[] | null => {
  const activeCategories = tree.children.filter(filterActive);
  if (tree.pageFolderId === id) {
    return activeCategories;
  }
  for (const category of activeCategories) {
    const categories = findCategories(category, id);
    if (categories) {
      return categories.length > 0 ? categories : activeCategories;
    }
  }
  return null;
};
export const CategorySlider: React.FC<Props> = ({
  navTree,
  pageFolder
}) => {
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  if (!isMobile || !navTree) {
    return null;
  }
  return <CategorySliderComponent navTree={navTree} pageFolder={pageFolder} data-sentry-element="CategorySliderComponent" data-sentry-component="CategorySlider" data-sentry-source-file="index.tsx" />;
};
const CategorySliderComponent: React.FC<Props> = ({
  navTree,
  pageFolder
}) => {
  const {
    t
  } = useI18n();
  const divRef = useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [scrollRight, setScrollRight] = useState<number>(0);
  const currentCategories = useMemo(() => {
    const categories = findCategories(navTree, pageFolder.pageFolderId);
    if (categories) {
      return categories.filter(({
        pageFolderId
      }) => pageFolderId !== pageFolder.pageFolderId);
    }
    return navTree.children.filter(filterActive);
  }, [navTree, pageFolder]);
  const calculateScroll = ({
    scrollLeft,
    scrollWidth,
    clientWidth
  }) => {
    setScrollLeft(scrollLeft);
    setScrollRight(scrollWidth - clientWidth - scrollLeft);
  };
  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => calculateScroll(event.target as HTMLDivElement);
  useEffect(() => {
    if (divRef) {
      calculateScroll(divRef.current);
    }
  }, [divRef]);
  return <FullPageWidthWrapper data-sentry-element="FullPageWidthWrapper" data-sentry-component="CategorySliderComponent" data-sentry-source-file="index.tsx">
      <div className={styles.categorySlider} style={{
      '--scroll-left': `${scrollLeft}px`,
      '--scroll-right': `${scrollRight}px`
    } as CSSProperties} onScroll={handleScroll} ref={divRef}>
        <ul>
          {currentCategories.map((c, index) => <li key={`sidebar-nav-${index}`}>
              <Link href={t(c._urls)}>
                {c.name}
              </Link>
            </li>)}
        </ul>
      </div>
    </FullPageWidthWrapper>;
};