import React from 'react';
import { Checkout } from '@engbers/components/online-shops';
import { IFTLinkReference, IFTPageFolderReference, IPaymentMethod, MediaType } from '@frontastic-engbers/types/engbers-custom';
import CheckoutDataProvider from '@engbers/components/online-shops/checkout/context/CheckoutDataProvider';
import PaymentProvider from '@engbers/components/online-shops/checkout/context/PaymentProvider';
interface ICheckoutData {
  headlineExistingCustomer: string;
  headlineNewCustomer: string;
  headlineGuest: string;
  textSize?: number;
  radioMister: string;
  radioMs: string;
  labelFirstName: string;
  labelLastName: string;
  labelStreet: string;
  labelHouse: string;
  labelCompany: string;
  labelZip: string;
  labelCity: string;
  labelCountry: string;
  labelBirthdate: string;
  labelEmail: string;
  labelEmailRepeat: string;
  labelPassword: string;
  labelPasswordRepeat: string;
  labelAgb: string;
  labelPostActions: string;
  labelCardAgb: string;
  errorInvalidMailFormat: string;
  headlineBillingAddress: string;
  migrationVerifyInformation: string;
  migrationVerifyBirthdate: string;
  headlineDelivery: string;
  headlineShippingAddress: string;
  textShippingAddress: string;
  headlinePayment: string;
  headlineOverview: string;
  headline: string;
  hasStoreDelivery: boolean;
  hasPackStationDelivery: boolean;
  billingAddressBoxIcon: MediaType;
  billingAddressBoxLabel: string;
  shippingAddressBoxIcon: MediaType;
  shippingAddressBoxLabel: string;
  storeDeliveryBoxIcon: MediaType;
  storeDeliveryBoxLabel: string;
  packStationBoxIcon: MediaType;
  packStationBoxLabel: string;
  headlinePackstation: string;
  textPackstation: string;
  labelSelectPackstation: string;
  labelPostNo: string;
  errorPackstation: string;
  errorPostNo: string;
  paymentMethods: IPaymentMethod[];
  paymentHintForGuest: string;
  paymentHintLockedProducts: string;
  paymentError: string;
  paymentDeniedError: string;
  paymentCreditCardAuthorized: string;
  paymentRedirectHeadline: string;
  paymentRedirectInfoText: string;
  paymentRedirectButtonLabel: string;
  solvencyFailedControlMessage: string;
  solvencyPositiveMessage: string;
  solvencyFailedMessage: string;
  solvencyInvoiceMessage: string;
  solvencyDebitMessage: string;
  solvencyModalTitle: string;
  solvencyModalTitleProgess: string;
  solvencyModalCancelLabel: string;
  solvencyModalSubmitLabel: string;
  overviewBillingAddressHeadline: string;
  overviewShippingAddressHeadline: string;
  overviewDeliveryTimeHeadline: string;
  overviewPaymentHeadline: string;
  overviewDeliveryTimeDE: string;
  overviewDeliveryTimeEU: string;
  headlineStoreDelivery: string;
  textStoreDelivery: string;
  labelOpeningHours: string;
  labelSelectStoreDelivery: string;
  errorStoreDelivery: string;
  storeClosed: string;
  fieldValidationMessage: string;
  forbiddenFieldList: string;
  voucherHeadline: string;
  voucherInfo: string;
  voucherRedeemBtn: string;
  differentSizeMessage: string;
  productOverviewArticle: string;
  productOverviewDesc: string;
  productOverviewAmount: string;
  productOverviewSum: string;
  pathSizeSuggestion?: IFTLinkReference | IFTPageFolderReference;
  commentOnOrderLabel: string;
  shippingCostsNote: string;
  showCommentOnOrderLabel: boolean;
  submitBtnLabel: string;
  enNewsletterLabel: string;
  emilioNewsletterLabel: string;
  xxlNewsletterLabel: string;
  snippets: any;
}
interface ICheckoutTasticComponent {
  data: ICheckoutData;
}
export const CheckoutTastic: React.FC<ICheckoutTasticComponent> = ({
  data
}) => {
  return <CheckoutDataProvider data-sentry-element="CheckoutDataProvider" data-sentry-component="CheckoutTastic" data-sentry-source-file="index.tsx">
      <PaymentProvider paymentMethods={data.paymentMethods} data-sentry-element="PaymentProvider" data-sentry-source-file="index.tsx">
        <Checkout headlines={{
        existing: data.headlineExistingCustomer,
        new: data.headlineNewCustomer,
        guest: data.headlineGuest,
        textSize: data.textSize
      }} addressLabels={{
        radioMister: data.radioMister,
        radioMs: data.radioMs,
        labelFirstName: data.labelFirstName,
        labelLastName: data.labelLastName,
        labelStreet: data.labelStreet,
        labelHouse: data.labelHouse,
        labelCompany: data.labelCompany,
        labelZip: data.labelZip,
        labelCity: data.labelCity,
        labelCountry: data.labelCountry,
        labelBirthdate: data.labelBirthdate,
        labelEmail: data.labelEmail,
        labelEmailRepeat: data.labelEmailRepeat,
        labelPassword: data.labelPassword,
        labelPasswordRepeat: data.labelPasswordRepeat,
        labelAgb: data.labelAgb,
        labelCardAgb: data.labelCardAgb,
        labelPostActions: data.labelPostActions,
        invalidMailFormat: data.errorInvalidMailFormat
      }} billingAddressSection={{
        headline: data.headlineBillingAddress,
        migrationVerifyInformation: data.migrationVerifyInformation,
        migrationVerifyBirthdate: data.migrationVerifyBirthdate
      }} shippingSection={{
        headline: data.headlineDelivery,
        hasStoreDelivery: data.hasStoreDelivery,
        hasPackStationDelivery: data.hasPackStationDelivery,
        billingAddressBoxIcon: data.billingAddressBoxIcon,
        billingAddressBoxLabel: data.billingAddressBoxLabel,
        shippingAddressBoxIcon: data.shippingAddressBoxIcon,
        shippingAddressBoxLabel: data.shippingAddressBoxLabel,
        storeDeliveryBoxIcon: data.storeDeliveryBoxIcon,
        storeDeliveryBoxLabel: data.storeDeliveryBoxLabel,
        packStationBoxIcon: data.packStationBoxIcon,
        packStationBoxLabel: data.packStationBoxLabel
      }} shippingAddressSection={{
        headlineShippingAddress: data.headlineShippingAddress,
        textShippingAddress: data.textShippingAddress
      }} packStationSection={{
        headlinePackstation: data.headlinePackstation,
        textPackstation: data.textPackstation,
        labelPostNo: data.labelPostNo,
        labelSelectPackstation: data.labelSelectPackstation,
        errorPackstation: data.errorPackstation,
        errorPostNo: data.errorPostNo
      }} storeDeliverySection={{
        headlineStoreDelivery: data.headlineStoreDelivery,
        textStoreDelivery: data.textStoreDelivery,
        labelOpeningHours: data.labelOpeningHours,
        labelSelectStoreDelivery: data.labelSelectStoreDelivery,
        errorStoreDelivery: data.errorStoreDelivery,
        storeClosed: data.storeClosed
      }} paymentSection={{
        headline: data.headlinePayment,
        paymentMethods: data.paymentMethods,
        paymentHintForGuest: data.paymentHintForGuest,
        paymentHintLockedProducts: data.paymentHintLockedProducts,
        paymentError: data.paymentError,
        paymentDeniedError: data.paymentDeniedError,
        paymentCreditCardAuthorized: data.paymentCreditCardAuthorized,
        paymentRedirectHeadline: data.paymentRedirectHeadline,
        paymentRedirectInfoText: data.paymentRedirectInfoText,
        paymentRedirectButtonLabel: data.paymentRedirectButtonLabel,
        solvencyFailedControlMessage: data.solvencyFailedControlMessage,
        solvencyPositiveMessage: data.solvencyPositiveMessage,
        solvencyFailedMessage: data.solvencyFailedMessage,
        solvencyInvoiceMessage: data.solvencyInvoiceMessage,
        solvencyDebitMessage: data.solvencyDebitMessage,
        solvencyModalTitle: data.solvencyModalTitle,
        solvencyModalTitleProgess: data.solvencyModalTitleProgess,
        solvencyModalCancelLabel: data.solvencyModalCancelLabel,
        solvencyModalSubmitLabel: data.solvencyModalSubmitLabel
      }} overviewSection={{
        headline: data.headlineOverview,
        overviewBillingAddressHeadline: data.overviewBillingAddressHeadline,
        overviewShippingAddressHeadline: data.overviewShippingAddressHeadline,
        overviewDeliveryTimeHeadline: data.overviewDeliveryTimeHeadline,
        overviewPaymentHeadline: data.overviewPaymentHeadline,
        overviewDifferentSizeMessage: data.differentSizeMessage
      }} forbiddenFields={{
        fieldValidationMessage: data.fieldValidationMessage,
        forbiddenFieldList: data.forbiddenFieldList
      }} voucherSection={{
        voucherHeadline: data.voucherHeadline,
        voucherInfo: data.voucherInfo,
        voucherRedeemBtn: data.voucherRedeemBtn,
        voucherRedeemedLabel: data.snippets?.dataSource?.snippetVoucherRedeemed,
        voucherInvalidLabel: data.snippets?.dataSource?.snippetVoucherErrorShort
      }} productOverviewSection={{
        productOverviewArticle: data.productOverviewArticle,
        productOverviewDesc: data.productOverviewDesc,
        productOverviewAmount: data.productOverviewAmount,
        productOverviewSum: data.productOverviewSum,
        pathSizeSuggestion: data.pathSizeSuggestion
      }} totalSummarySection={{
        commentOnOrderLabel: data.commentOnOrderLabel,
        shippingCostsNote: data.shippingCostsNote,
        showCommentOnOrderLabel: data.showCommentOnOrderLabel
      }} ctaSection={{
        submitBtnLabel: data.submitBtnLabel,
        enNewsletterLabel: data.enNewsletterLabel,
        emilioNewsletterLabel: data.emilioNewsletterLabel,
        xxlNewsletterLabel: data.xxlNewsletterLabel
      }} data-sentry-element="Checkout" data-sentry-source-file="index.tsx" />
      </PaymentProvider>
    </CheckoutDataProvider>;
};