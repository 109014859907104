import React, { Ref } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import classnames from 'classnames';
import { RaffleFormData } from '@frontastic-engbers/types/engbers-custom';
import { Block, Button, CountrySelect, InputCheckbox, InputDate, InputRadioGroup, InputText, Markdown } from '@engbers/components';
import { RaffleQuiz } from './raffleQuiz';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { validateStreetName } from '@frontastic-engbers/helpers/utils/validateStreetName';
import { IRaffleForm, SpecialCharErrors, StreetNamePostalCodeErrors, ValidationErrors } from './index';
import styles from './raffle.module.scss';
import { containsExcludedChars } from '@frontastic-engbers/helpers/utils/webserviceExcludedChars';
interface IRaffleMobileForm {
  formData: RaffleFormData;
  setFormData: React.Dispatch<React.SetStateAction<RaffleFormData>>;
  setValidBirthdateFormat: React.Dispatch<React.SetStateAction<boolean>>;
  errors: Partial<ValidationErrors>;
  setErrors: React.Dispatch<React.SetStateAction<Partial<ValidationErrors>>>;
  specialCharErrors: Partial<SpecialCharErrors>;
  setSpecialCharErrors: React.Dispatch<React.SetStateAction<Partial<SpecialCharErrors>>>;
  loading: boolean;
  initialSend: boolean;
  emailErrors: string;
  streetNamePostalCodeErrors: Partial<StreetNamePostalCodeErrors>;
  setStreetNamePostalCodeErrors: React.Dispatch<React.SetStateAction<Partial<StreetNamePostalCodeErrors>>>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCheckboxChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  validatePostalCodeField: (value: string, country: string) => void;
  validateEmail: () => Promise<boolean>;
  recaptchaRef: Ref<HTMLDivElement>;
}
export const RaffleMobileForm: React.FC<IRaffleForm & IRaffleMobileForm> = ({
  formData,
  setFormData,
  setValidBirthdateFormat,
  errors,
  setErrors,
  specialCharErrors,
  setSpecialCharErrors,
  loading,
  initialSend,
  emailErrors,
  streetNamePostalCodeErrors,
  setStreetNamePostalCodeErrors,
  handleChange,
  handleCheckboxChange,
  handleSubmit,
  validatePostalCodeField,
  validateEmail,
  recaptchaRef,
  raffleName,
  newsletterOption,
  reCaptchaKey,
  labels,
  quiz
}) => {
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const mailMessage = formatErrorMessage({
    id: 'email.wrong',
    defaultMessage: 'E-Mail Adresse format ist invalid.'
  });
  const mailErrorMessage = formatAccountMessage({
    id: 'email.enter.again',
    defaultMessage: 'Please enter your e-mail address.'
  });
  return <>
      <Markdown text={raffleName} className={styles.headline} data-sentry-element="Markdown" data-sentry-source-file="raffle-mobile.tsx" />
      {quiz?.showQuiz && quiz?.quizQuestion?.length && <RaffleQuiz formData={formData} setFormData={setFormData} errors={errors} setErrors={setErrors} initialSend={initialSend} quiz={quiz} isMobile />}
      <form onSubmit={handleSubmit}>
        <Block marginBottom={8} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <InputRadioGroup onChange={id => {
            setFormData(prev => ({
              ...prev,
              salutation: id as any
            }));
            if (id) {
              setErrors(state => ({
                ...state,
                salutation: true
              }));
            }
          }} options={[{
            label: labels.radioMister ?? 'MR',
            id: 'MR'
          }, {
            label: labels.radioMs ?? 'MRS',
            id: 'MRS'
          }]} activeId={formData.salutation} errorMessage={errors.salutation === false && initialSend ? formatMessage({
            id: 'salutation'
          }) + formatErrorMessage({
            id: 'mandatory.error'
          }) : undefined} data-sentry-element="InputRadioGroup" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="title">
              {labels.labelTitel ?? formatMessage({
              id: 'firstName',
              defaultMessage: 'Forename'
            })}
            </label>
            <InputText style="grey" id="title" name="title" type="text" onChange={handleChange} placeholder={labels.labelTitel ?? formatMessage({
            id: 'title',
            defaultMessage: 'title'
          })} value={formData.title} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="firstName">
              {labels.labelFirstName ?? formatMessage({
              id: 'firstName',
              defaultMessage: 'Forename'
            })}
            </label>
            <InputText style="grey" id="firstName" errorMessage={specialCharErrors.firstName ? formatErrorMessage({
            id: 'specialChars'
          }) : !errors.firstName && initialSend && formatErrorMessage({
            id: 'fillInput'
          })} name="firstName" type="text" onChange={handleChange} onBlur={() => {
            setErrors(prevErrors => ({
              ...prevErrors,
              firstName: formData.firstName !== ''
            }));
            setSpecialCharErrors(state => ({
              ...state,
              firstName: containsExcludedChars(formData.firstName)
            }));
          }} placeholder={labels.labelFirstName ?? formatMessage({
            id: 'firstName',
            defaultMessage: 'Forename'
          })} required value={formData.firstName} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="lastName">
              {labels.labelLastName ?? formatMessage({
              id: 'lastName',
              defaultMessage: 'Surname'
            })}
            </label>
            <InputText style="grey" errorMessage={specialCharErrors.lastName ? formatErrorMessage({
            id: 'specialChars'
          }) : !errors.lastName && initialSend && formatErrorMessage({
            id: 'fillInput'
          })} id="lastName" name="lastName" type="text" onChange={handleChange} onBlur={() => {
            setErrors(prevErrors => ({
              ...prevErrors,
              lastName: formData.lastName !== ''
            }));
            setSpecialCharErrors(state => ({
              ...state,
              lastName: containsExcludedChars(formData.lastName)
            }));
          }} placeholder={labels.labelLastName ?? formatMessage({
            id: 'lastName',
            defaultMessage: 'Surname'
          })} required value={formData.lastName} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="streetName">
              {labels.labelStreetName ?? formatMessage({
              id: 'street.name',
              defaultMessage: 'street.name'
            })}
            </label>
            <InputText style="grey" id="streetName" name="streetName" type="text" required value={formData.streetName} onChange={e => {
            handleChange(e);
            setStreetNamePostalCodeErrors(state => ({
              ...state,
              streetName: !validateStreetName(e.target.value)
            }));
          }} onBlur={e => {
            setErrors(prevErrors => ({
              ...prevErrors,
              zipCode: formData.streetName !== ''
            }));
            setSpecialCharErrors(state => ({
              ...state,
              streetName: containsExcludedChars(formData.streetName)
            }));
            setStreetNamePostalCodeErrors(state => ({
              ...state,
              streetName: !validateStreetName(e.target.value)
            }));
          }} placeholder={labels.labelStreetName ?? formatMessage({
            id: 'street.name',
            defaultMessage: 'street.name'
          })} errorMessage={specialCharErrors.streetName ? formatErrorMessage({
            id: 'specialChars'
          }) : !errors.streetName && initialSend ? formatMessage({
            id: 'street.name'
          }) + formatErrorMessage({
            id: 'mandatory.error'
          }) : streetNamePostalCodeErrors.streetName ? formatErrorMessage({
            id: 'streetNameCharacters',
            defaultMessage: 'Straße darf keine Zahlen enthalten!'
          }) : undefined} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="streetNumber">
              {labels.labelStreetNumber ?? formatMessage({
              id: 'street.number',
              defaultMessage: 'street.number'
            })}
            </label>
            <InputText style="grey" id="streetNumber" name="streetNumber" type="number" required value={formData.streetNumber} onChange={handleChange} onBlur={() => {
            setErrors(prevErrors => ({
              ...prevErrors,
              streetNumber: formData.streetNumber !== ''
            }));
            setSpecialCharErrors(state => ({
              ...state,
              streetNumber: containsExcludedChars(formData.streetNumber)
            }));
          }} placeholder={labels.labelStreetNumber ?? formatMessage({
            id: 'street.number',
            defaultMessage: 'street.number'
          })} errorMessage={specialCharErrors.streetNumber ? formatErrorMessage({
            id: 'specialChars'
          }) : !errors.city && initialSend ? formatMessage({
            id: 'street.number'
          }) + formatErrorMessage({
            id: 'mandatory.error'
          }) : undefined} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="additionalInfo">
              {labels.labelAdditionalInfo ?? formatMessage({
              id: 'additionalInfo',
              defaultMessage: 'additionalInfo'
            })}
            </label>
            <InputText style="grey" id="additionalInfo" name="additionalInfo" type="text" onChange={handleChange} placeholder={labels.labelAdditionalInfo ?? formatMessage({
            id: 'additionalInfo',
            defaultMessage: 'additionalInfo'
          })} value={formData.additionalInfo} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="zipCode">
              {labels.labelZip ?? formatMessage({
              id: 'zipCode',
              defaultMessage: 'zipCode'
            })}
            </label>
            <InputText style="grey" id="zipCode" name="zipCode" type="number" autoComplete="zipCode" required value={formData.zipCode} onChange={handleChange} onBlur={() => {
            setErrors(prevErrors => ({
              ...prevErrors,
              zipCode: formData.zipCode !== ''
            }));
            setSpecialCharErrors(state => ({
              ...state,
              zipCode: containsExcludedChars(formData.zipCode)
            }));
          }} placeholder={labels.labelZip ?? formatMessage({
            id: 'zipCode',
            defaultMessage: 'zipCode'
          })} errorMessage={specialCharErrors.zipCode ? formatErrorMessage({
            id: 'specialChars'
          }) : !errors.zipCode && initialSend ? formatMessage({
            id: 'zipCode'
          }) + formatErrorMessage({
            id: 'mandatory.error'
          }) : undefined} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="city">
              {labels.labelCity ?? formatMessage({
              id: 'place',
              defaultMessage: 'place'
            })}
            </label>
            <InputText style="grey" id="city" name="city" type="text" autoComplete="city" required value={formData.city} onChange={handleChange} onBlur={() => {
            setErrors(prevErrors => ({
              ...prevErrors,
              city: formData.city !== ''
            }));
            setSpecialCharErrors(state => ({
              ...state,
              city: containsExcludedChars(formData.city)
            }));
          }} placeholder={labels.labelCity ?? formatMessage({
            id: 'place',
            defaultMessage: 'place'
          })} errorMessage={specialCharErrors.city ? formatErrorMessage({
            id: 'specialChars'
          }) : !errors.city && initialSend ? formatMessage({
            id: 'place'
          }) + formatErrorMessage({
            id: 'mandatory.error'
          }) : undefined} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <CountrySelect id="address_country" placeholder={labels.labelCountry} name="country" errorMessage={errors.country === false ? formatMessage({
            id: 'country'
          }) + formatErrorMessage({
            id: 'mandatory.error'
          }) : undefined} autoComplete="" required value={formData.country} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            const isCountryIreland = e.target.value.toLowerCase() === 'ie';
            setFormData(prev => ({
              ...prev,
              country: e.target.value
            }));
            validatePostalCodeField(formData.zipCode, e.target.value);
            setErrors(state => ({
              ...state,
              zipCode: isCountryIreland || formData.zipCode !== ''
            }));
          }} wrapperCustomStyle={{
            width: '100%'
          }} data-sentry-element="CountrySelect" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="phone">
              {labels.labelTelephone ?? formatMessage({
              id: 'phone',
              defaultMessage: 'phone'
            })}
            </label>
            <InputText style="grey" id="phone" name="phoneNumber" type="number" onChange={handleChange} placeholder={labels.labelTelephone ?? formatMessage({
            id: 'phone',
            defaultMessage: 'phone'
          })} value={formData.phoneNumber} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="mobilephone">
              {labels.labelMobilephone ?? formatMessage({
              id: 'phoneMobile',
              defaultMessage: 'phoneMobile'
            })}
            </label>
            <InputText style="grey" id="mobilephone" name="mobileNumber" type="number" onChange={handleChange} placeholder={labels.labelMobilephone ?? formatMessage({
            id: 'phoneMobile',
            defaultMessage: 'phoneMobile'
          })} value={formData.mobileNumber} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <InputDate label={labels.labelBirthdate} required onChange={value => setFormData(prev => ({
            ...prev,
            ...value
          }))} isFormatValid={valid => {
            setValidBirthdateFormat(valid);
            setErrors(state => ({
              ...state,
              birthdayDay: valid,
              birthdayMonth: valid,
              birthdayYear: valid
            }));
          }} stillHasError={errors && initialSend && (!errors.birthdayDay || !errors.birthdayMonth || !errors.birthdayYear)} birthdayDay={formData.birthdayDay} birthdayMonth={formData.birthdayMonth} birthdayYear={formData.birthdayYear} data-sentry-element="InputDate" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only" htmlFor="email">
              {labels.labelEmail ?? formatMessage({
              id: 'email',
              defaultMessage: 'Email'
            })}
            </label>
            <InputText style="grey" id="email" errorMessage={!emailErrors ? undefined : emailErrors === 'empty' ? mailErrorMessage : mailMessage} name="email" type="email" onChange={handleChange} onBlur={validateEmail} placeholder={labels.labelEmail ?? formatMessage({
            id: 'email',
            defaultMessage: 'Email'
          })} required value={formData.email} data-sentry-element="InputText" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <label className="sr-only">
              {labels.labelRecaptcha ?? formatMessage({
              id: 'reCaptchaSecurityCode',
              defaultMessage: 'accept ReCaptcha'
            })}
            </label>
            <ReCAPTCHA ref={recaptchaRef} sitekey={reCaptchaKey} onChange={v => setErrors(prevErrors => ({
            ...prevErrors,
            acceptReCaptcha: v !== ''
          }))} data-sentry-element="ReCAPTCHA" data-sentry-source-file="raffle-mobile.tsx" />
            <span className={styles.errorMsg}>
              {!errors.acceptReCaptcha && initialSend && formatErrorMessage({
              id: 'fillInput',
              defaultMessage: 'fillInput'
            })}
            </span>
          </Block>
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <InputCheckbox id="accept_tos" name="acceptTos" label={<span className={classnames({
            [styles.errorMsgColor]: !errors.acceptTos
          })}>
                  <Markdown linkToNewTab text={labels.labelTextTos} className={styles.markdownGuidelines} />
                </span>} onChange={handleCheckboxChange} errorMessage={!errors.acceptTos && initialSend ? formatErrorMessage({
            id: 'acceptConditionsOfParticipation'
          }) : undefined} hasHoverEffect data-sentry-element="InputCheckbox" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          {newsletterOption && <Block marginBottom={4}>
              <InputCheckbox id="newsletterAcceptions" name="wantsNewsletter" label={labels.labelNewsletterOption} onChange={handleCheckboxChange} />
            </Block>}
          <Block marginBottom={4} data-sentry-element="Block" data-sentry-source-file="raffle-mobile.tsx">
            <InputCheckbox id="partnerConditions" name="partnerConditions" label={labels.labelRaffleOriginInfos} onChange={handleCheckboxChange} data-sentry-element="InputCheckbox" data-sentry-source-file="raffle-mobile.tsx" />
          </Block>
          <Button onClick={handleSubmit} isLoading={loading} size="large" label={labels.labelMobileBtnSubmit ?? formatMessage({
          id: 'submit',
          defaultMessage: 'Submit'
        })} data-sentry-element="Button" data-sentry-source-file="raffle-mobile.tsx" />
        </Block>
      </form>
    </>;
};