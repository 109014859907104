import { useMemo } from 'react';
import { Order } from '@frontastic-engbers/types/cart/Order';
import { ShippingMethod } from '@frontastic-engbers/types/cart/ShippingMethod';
import { ShippingProvider } from '@frontastic-engbers/types/engbers-custom';
import { computeShippingTime } from '../utils/getShippingInfo';

export const useShippingTime = (
  source: Order,
  shippingProvider: ShippingProvider[],
  shippingMethods?: ShippingMethod[],
) => {
  return useMemo(() => {
    return computeShippingTime(source, shippingProvider, shippingMethods);
  }, [
    source?.shippingInfo?.shippingMethodId,
    source?.shippingAddress?.country,
    source?.shippingType,
    shippingProvider,
    shippingMethods,
  ]);
};
