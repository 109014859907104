import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import ReCAPTCHA from 'react-google-recaptcha';
import { tz } from 'moment-timezone';
import classnames from 'classnames';
import { IFTLinkReference, IFTPageFolderReference, RaffleData, RaffleFormData } from '@frontastic-engbers/types/engbers-custom';
import { NewsletterData } from '@engbers/webservice/generated/service/definitions/NewsletterData';
import { sendRaffleRequest } from '@frontastic-engbers/lib/actions/service';
import { Block, Button, CountrySelect, InputCheckbox, InputDate, InputRadioGroup, InputText, Markdown } from '@engbers/components';
import { RaffleMobileForm } from './raffle-mobile';
import { RaffleQuiz } from './raffleQuiz';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { validatePostalCode } from '@frontastic-engbers/helpers/utils/validatePostalCode';
import { validateDateFormat } from '@frontastic-engbers/helpers/utils/validateDateFormat';
import { useToastNotificationsActions } from '@frontastic-engbers/lib/state/notification/actions';
import { EmailValidatorHelper } from '@frontastic-engbers/helpers/emailValidatorHelper';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { getProjectInfo } from '@frontastic-engbers/lib';
import isoAlpha2Data from '@frontastic-engbers/helpers/utils/iso-alpha2-data';
import { containsExcludedChars } from '@frontastic-engbers/helpers/utils/webserviceExcludedChars';
import styles from './raffle.module.scss';
export interface IRaffleForm {
  raffleId?: string;
  raffleName: string;
  newsletterOption: boolean;
  thankYouUrl?: IFTLinkReference | IFTPageFolderReference;
  newsletter?: NewsletterData[]; //@TODO für spätere Anpassung schonmal hinugefügt;
  reCaptchaKey: string;
  labels: {
    radioMister: string;
    radioMs: string;
    labelTitel: string;
    labelFirstName: string;
    labelLastName: string;
    labelStreetName: string;
    labelStreetNumber: string;
    labelAdditionalInfo: string;
    labelZip: string;
    labelCity: string;
    labelCountry: string;
    labelTelephone: string;
    labelMobilephone: string;
    labelBirthdate: string;
    labelEmail: string;
    labelRecaptcha: string;
    labelNewsletterOption?: string;
    labelRaffleOriginInfos: string;
    labelMobileBtnSubmit: string;
    labelDesktopBtnSubmit: string;
    labelTextTos: string;
  };
  startDateTime?: string;
  endDateTime?: string;
  fallbackText?: string;
  quiz: IQuizContent;
  isFullWidth?: boolean;
}
export interface IQuizContent {
  showQuiz: boolean;
  quizQuestion: string;
  answersType: 'input' | 'radio' | 'checkbox';
  quizAnswers: {
    answerLabel: string;
    answerValue: string;
  }[];
  unselectedAnswerErrMsg: string;
}
export type StreetNamePostalCodeErrors = {
  streetName: boolean;
  postalCode: boolean;
};
export type ValidationErrors = {
  salutation?: boolean;
  firstName: boolean;
  lastName: boolean;
  streetName: boolean;
  streetNumber: boolean;
  zipCode: boolean;
  city: boolean;
  country: boolean;
  birthdayDay: boolean;
  birthdayMonth: boolean;
  birthdayYear: boolean;
  email: boolean;
  acceptReCaptcha: boolean;
  acceptTos: boolean;
  quizAnswer: boolean;
};
export type SpecialCharErrors = Pick<ValidationErrors, 'firstName' | 'lastName' | 'streetName' | 'streetNumber' | 'zipCode' | 'city'> & {
  title: boolean;
  additionalInfo: boolean;
};
export const RaffleForm: React.FC<IRaffleForm> = ({
  raffleId,
  raffleName,
  newsletterOption,
  thankYouUrl,
  newsletter,
  reCaptchaKey,
  labels,
  startDateTime,
  endDateTime,
  fallbackText,
  quiz,
  isFullWidth
}) => {
  const router = useRouter();
  const {
    pushNotification
  } = useToastNotificationsActions();
  const {
    formatMessage: formatAccountMessage
  } = useFormat({
    name: 'account'
  });
  const {
    formatMessage: formatErrorMessage
  } = useFormat({
    name: 'error'
  });
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const [errors, setErrors] = useState<Partial<ValidationErrors>>({});
  const [specialCharErrors, setSpecialCharErrors] = useState<Partial<SpecialCharErrors>>({});
  const [loading, setLoading] = useState(false);
  const [emailErrors, setEmailErrors] = useState<string | null>(null);
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const [initialSend, setInitialSend] = useState<boolean>(false);
  const [validBirthdateFormat, setValidBirthdateFormat] = useState<boolean>(true);
  const [submitTrigger, setSubmitTrigger] = useState<boolean>(false);
  const [streetNamePostalCodeErrors, setStreetNamePostalCodeErrors] = useState<Partial<StreetNamePostalCodeErrors>>({});
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const recaptchaRef = useRef();
  const projectInfo = getProjectInfo();
  const isPwa = projectInfo['projectId'] === 'Pwa';
  const defaultCountry = Object.keys(isoAlpha2Data).find(key => isoAlpha2Data[key] === isoAlpha2Data.DE);
  const [formData, setFormData] = useState<RaffleFormData>({
    salutation: null,
    title: '',
    firstName: '',
    lastName: '',
    streetName: '',
    streetNumber: '',
    additionalInfo: '',
    city: '',
    zipCode: '',
    country: isPwa ? '' : defaultCountry,
    phoneNumber: '',
    mobileNumber: '',
    birthdayDay: '',
    birthdayMonth: '',
    birthdayYear: '',
    email: '',
    acceptTos: false,
    partnerConditions: false,
    wantsNewsletter: false,
    quizQuestion: quiz?.showQuiz && quiz?.quizQuestion ? quiz?.quizQuestion : '',
    raffleAnswer1: '',
    raffleAnswer2: '',
    raffleAnswer3: ''
  });
  const mailMessage = formatErrorMessage({
    id: 'email.wrong',
    defaultMessage: 'E-Mail Adresse format ist invalid.'
  });
  const mailErrorMessage = formatAccountMessage({
    id: 'email.enter.again',
    defaultMessage: 'Please enter your e-mail address.'
  });
  const expired = useMemo(() => {
    const startTime = tz(startDateTime, 'Europe/Berlin');
    const endTime = tz(endDateTime, 'Europe/Berlin');
    const now = tz('Europe/Berlin');
    return startDateTime?.length && endDateTime?.length ? !now.isBetween(startTime, endTime) : false;
  }, []);
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  if (expired) {
    return <Markdown text={fallbackText} className={classnames({
      [styles.raffleFullWidth]: isFullWidth
    })} />;
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
  const validate = () => {
    const validationErrors: ValidationErrors = {
      salutation: formData.salutation !== null,
      firstName: formData.firstName !== '',
      lastName: formData.lastName !== '',
      zipCode: formData.zipCode !== '',
      city: formData.city !== '',
      email: formData.email !== '',
      acceptTos: formData.acceptTos,
      streetName: formData.streetName !== '',
      streetNumber: formData.streetNumber !== '',
      country: formData.country !== '',
      birthdayDay: formData.birthdayDay !== '' && validBirthdateFormat,
      birthdayMonth: formData.birthdayMonth !== '' && validBirthdateFormat,
      birthdayYear: formData.birthdayYear !== '' && validBirthdateFormat,
      acceptReCaptcha: (recaptchaRef.current as any)?.getValue() !== '',
      quizAnswer: quiz?.showQuiz && quiz?.quizQuestion?.length ? formData.raffleAnswer1 !== '' || formData.raffleAnswer2 !== '' || formData.raffleAnswer3 !== '' : true
    };
    const specialChars: SpecialCharErrors = {
      title: containsExcludedChars(formData.title),
      firstName: containsExcludedChars(formData.firstName),
      lastName: containsExcludedChars(formData.lastName),
      streetName: containsExcludedChars(formData.streetName),
      streetNumber: containsExcludedChars(formData.streetNumber),
      additionalInfo: containsExcludedChars(formData.additionalInfo),
      zipCode: containsExcludedChars(formData.zipCode),
      city: containsExcludedChars(formData.city)
    };
    setErrors(validationErrors);
    setSpecialCharErrors(specialChars);
    setSubmitTrigger(false);
    return !Object.values(validationErrors).includes(false) && !Object.values(specialChars).includes(true);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitTrigger(true);
    setInitialSend(true);
    setLoading(true);
    const validEmail = await validateEmail();
    if (!validate() || !validEmail) {
      setLoading(false);
      return;
    }
    const raffleData: RaffleData = {
      raffleId,
      formData
    };
    const response = await sendRaffleRequest(raffleData);
    if (response.code === 'SUCCESS' && thankYouUrl || response.code === 'MAIL DUPLICATED') {
      router.push(getReferenceTarget(thankYouUrl));
    } else {
      pushNotification(formatErrorMessage({
        id: 'wentWrong',
        defaultMessage: 'Es tut uns leid'
      }), 'error');
      setLoading(false);
    }
  };
  const validatePostalCodeField = (value: string, country: string = null) => {
    const isCountryGermany = country ? country.toLowerCase() === 'de' : formData.country.toLowerCase() === 'de';
    if (isCountryGermany) {
      setStreetNamePostalCodeErrors(state => ({
        ...state,
        postalCode: !validatePostalCode(value)
      }));
    }
    setStreetNamePostalCodeErrors(state => ({
      ...state,
      postalCode: isCountryGermany ? !validatePostalCode(value) : false
    }));
  };
  const validateEmail = async (): Promise<boolean> => {
    const ruleDefinitions: string | null = await EmailValidatorHelper.ruleDefinitions(formData.email);
    if (!ruleDefinitions) {
      setEmailErrors(null);
      setErrors(prevErrors => ({
        ...prevErrors,
        email: true
      }));
      return true;
    }
    setEmailErrors(ruleDefinitions);
    setErrors(prevErrors => ({
      ...prevErrors,
      email: false
    }));
    return false;
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData(prevData => ({
      ...prevData,
      [e.target.name]: e.target.checked
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      acceptTos: e.target.checked
    }));
  };
  if (isMinWidthMedium) {
    return <RaffleMobileForm formData={formData} setFormData={setFormData} setValidBirthdateFormat={setValidBirthdateFormat} errors={errors} setErrors={setErrors} specialCharErrors={specialCharErrors} setSpecialCharErrors={setSpecialCharErrors} loading={loading} initialSend={initialSend} emailErrors={emailErrors} streetNamePostalCodeErrors={streetNamePostalCodeErrors} setStreetNamePostalCodeErrors={setStreetNamePostalCodeErrors} handleChange={handleChange} handleCheckboxChange={handleCheckboxChange} handleSubmit={handleSubmit} validatePostalCodeField={validatePostalCodeField} validateEmail={validateEmail} recaptchaRef={recaptchaRef} raffleName={raffleName} newsletterOption={newsletterOption} reCaptchaKey={reCaptchaKey} labels={labels} quiz={quiz} />;
  }
  const inputCustomStyle = {
    height: '28px',
    padding: '0 8px'
  };
  const blockCustomStyle = {
    width: '100%',
    display: 'inline-flex'
  };
  return <div className={classnames({
    [styles.raffleFullWidth]: isFullWidth
  })} data-sentry-component="RaffleForm" data-sentry-source-file="index.tsx">
      <Markdown text={raffleName} className={styles.headline} data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
      {quiz?.showQuiz && quiz?.quizQuestion?.length && <RaffleQuiz formData={formData} setFormData={setFormData} errors={errors} setErrors={setErrors} initialSend={initialSend} quiz={quiz} />}
      <form onSubmit={handleSubmit}>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="salutation">
            {formatMessage({
            id: 'salutation',
            defaultMessage: 'salutation'
          }) + ' *'}
          </label>
          <InputRadioGroup onChange={id => {
          setFormData(prev => ({
            ...prev,
            salutation: id as any
          }));
          if (id) {
            setErrors(state => ({
              ...state,
              salutation: true
            }));
          }
        }} options={[{
          label: `${labels.radioMister ?? 'MR'}`,
          id: `${labels.radioMister ?? 'MR'}`
        }, {
          label: `${labels.radioMs ?? 'MS'}`,
          id: `${labels.radioMs ?? 'MS'}`
        }]} activeId={formData.salutation} errorMessage={errors.salutation === false && initialSend ? formatMessage({
          id: 'salutation'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} data-sentry-element="InputRadioGroup" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="title">
            {labels.labelTitel ? `${labels.labelTitel}` : formatMessage({
            id: 'title',
            defaultMessage: 'title'
          })}
          </label>
          <InputText style="white" name="title" type="text" value={formData.title} onChange={handleChange} onBlur={() => {
          setSpecialCharErrors(state => ({
            ...state,
            title: containsExcludedChars(formData.title)
          }));
        }} errorMessage={specialCharErrors.title ? formatErrorMessage({
          id: 'specialChars'
        }) : undefined} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="firstName">
            {labels.labelFirstName ? `${labels.labelFirstName} *` : formatMessage({
            id: 'firstName',
            defaultMessage: 'Forename'
          })}
          </label>
          <InputText style="white" name="firstName" type="text" autoComplete="given-name" required value={formData.firstName} onChange={handleChange} onBlur={() => {
          setErrors(prevErrors => ({
            ...prevErrors,
            firstName: formData.firstName !== ''
          }));
          setSpecialCharErrors(state => ({
            ...state,
            firstName: containsExcludedChars(formData.firstName)
          }));
        }} errorMessage={specialCharErrors.firstName ? formatErrorMessage({
          id: 'specialChars'
        }) : !errors.firstName && initialSend ? formatMessage({
          id: 'firstName'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="lastName">
            {labels.labelLastName ? `${labels.labelLastName} *` : formatMessage({
            id: 'lastName',
            defaultMessage: 'Surname'
          })}
          </label>
          <InputText style="white" id="last_name" name="lastName" type="text" autoComplete="family-name" required value={formData.lastName} onChange={handleChange} onBlur={() => {
          setErrors(prevErrors => ({
            ...prevErrors,
            lastName: formData.lastName !== ''
          }));
          setSpecialCharErrors(state => ({
            ...state,
            lastName: containsExcludedChars(formData.lastName)
          }));
        }} errorMessage={specialCharErrors.lastName ? formatErrorMessage({
          id: 'specialChars'
        }) : !errors.lastName && initialSend ? formatMessage({
          id: 'lastName'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="street_nr">
            {labels.labelStreetName && labels.labelStreetNumber ? `${labels.labelStreetName}, ${labels.labelStreetNumber} *` : `${formatMessage({
            id: 'street.name',
            defaultMessage: 'street.name'
          })}, ${formatMessage({
            id: 'street.number',
            defaultMessage: 'street.number'
          })}`}
          </label>
          <div className="inline-flex w-3/4">
            <Block maxWidth="80%" customStyle={{
            width: '100%'
          }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
              <InputText style="white" id="streetName" name="streetName" type="text" required value={formData.streetName} onChange={handleChange} onBlur={() => {
              setErrors(prevErrors => ({
                ...prevErrors,
                streetName: formData.streetName !== ''
              }));
              setSpecialCharErrors(state => ({
                ...state,
                streetName: containsExcludedChars(formData.streetName)
              }));
            }} errorMessage={specialCharErrors.streetName ? formatErrorMessage({
              id: 'specialChars'
            }) : !errors.streetName && initialSend ? formatMessage({
              id: 'street.name'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} wrapperCustomStyle={{
              paddingRight: '15px'
            }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
            </Block>
            <Block maxWidth="20%" customStyle={{
            width: '100%'
          }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
              <InputText style="white" id="streetNumber" name="streetNumber" type="number" required value={formData.streetNumber} onChange={handleChange} onBlur={() => {
              setErrors(prevErrors => ({
                ...prevErrors,
                streetNumber: formData.streetNumber !== ''
              }));
              setSpecialCharErrors(state => ({
                ...state,
                streetNumber: containsExcludedChars(formData.streetNumber)
              }));
            }} errorMessage={specialCharErrors.streetNumber ? formatErrorMessage({
              id: 'specialChars'
            }) : !errors.streetNumber && initialSend ? formatMessage({
              id: 'street.number'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} wrapperCustomStyle={{
              paddingLeft: '15px'
            }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
            </Block>
          </div>
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="additionalInfo">
            {labels.labelAdditionalInfo ? `${labels.labelAdditionalInfo}` : formatMessage({
            id: 'additionalInfo',
            defaultMessage: 'additionalInfo'
          })}
          </label>
          <InputText style="white" id="additionalInfo" name="additionalInfo" type="text" required value={formData.additionalInfo} onChange={handleChange} onBlur={() => {
          setSpecialCharErrors(state => ({
            ...state,
            additionalInfo: containsExcludedChars(formData.additionalInfo)
          }));
        }} errorMessage={specialCharErrors.additionalInfo ? formatErrorMessage({
          id: 'specialChars'
        }) : undefined} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="zipCode">
            {labels.labelZip && labels.labelCity ? `${labels.labelZip}, ${labels.labelCity} *` : `${formatMessage({
            id: 'zipCodeShort',
            defaultMessage: 'zipcode'
          })}, ${formatMessage({
            id: 'place',
            defaultMessage: 'place'
          })}`}
          </label>
          <div className="inline-flex w-3/4">
            <Block maxWidth="30%" customStyle={{
            width: '100%'
          }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
              <InputText style="white" id="zipCode" name="zipCode" type="number" required value={formData.zipCode} onChange={handleChange} onBlur={() => {
              setErrors(prevErrors => ({
                ...prevErrors,
                zipCode: formData.zipCode !== ''
              }));
              setSpecialCharErrors(state => ({
                ...state,
                zipCode: containsExcludedChars(formData.zipCode)
              }));
            }} errorMessage={specialCharErrors.zipCode ? formatErrorMessage({
              id: 'specialChars'
            }) : !errors.zipCode && initialSend ? formatMessage({
              id: 'zipCode'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} wrapperCustomStyle={{
              paddingRight: '15px'
            }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
            </Block>
            <Block maxWidth="70%" customStyle={{
            width: '100%'
          }} data-sentry-element="Block" data-sentry-source-file="index.tsx">
              <InputText style="white" id="city" name="city" type="text" required value={formData.city} onChange={handleChange} onBlur={() => {
              setErrors(prevErrors => ({
                ...prevErrors,
                city: formData.city !== ''
              }));
              setSpecialCharErrors(state => ({
                ...state,
                city: containsExcludedChars(formData.city)
              }));
            }} errorMessage={specialCharErrors.city ? formatErrorMessage({
              id: 'specialChars'
            }) : !errors.city && initialSend ? formatMessage({
              id: 'place'
            }) + formatErrorMessage({
              id: 'mandatory.error'
            }) : undefined} wrapperCustomStyle={{
              paddingLeft: '15px'
            }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
            </Block>
          </div>
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="country">
            {labels.labelCountry ?? formatMessage({
            id: 'country',
            defaultMessage: 'country'
          })}
          </label>
          <CountrySelect id="country" name="country" errorMessage={errors.country === false ? formatMessage({
          id: 'country'
        }) + formatErrorMessage({
          id: 'mandatory.error'
        }) : undefined} autoComplete="" required value={formData.country} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          const isCountryIreland = e.target.value.toLowerCase() === 'ie';
          setFormData(prev => ({
            ...prev,
            country: e.target.value
          }));
          validatePostalCodeField(formData.zipCode, e.target.value);
          setErrors(state => ({
            ...state,
            zipCode: isCountryIreland || formData.zipCode !== ''
          }));
        }} wrapperCustomStyle={{
          width: '75%'
        }} data-sentry-element="CountrySelect" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="phone">
            {labels.labelTelephone ?? formatMessage({
            id: 'phone',
            defaultMessage: 'enterPhoneNumber'
          })}
          </label>
          <InputText style="white" id="phone" name="phoneNumber" type="number" autoComplete="phone" onChange={handleChange} value={formData.phoneNumber} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="mobileNumber">
            {labels.labelMobilephone ?? formatMessage({
            id: 'phoneMobile',
            defaultMessage: 'phoneMobile'
          })}
          </label>
          <InputText style="white" id="mobileNumber" name="mobileNumber" type="number" onChange={handleChange} value={formData.mobileNumber} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="birthdate">
            {labels.labelBirthdate ? `${labels.labelBirthdate} *` : formatMessage({
            id: 'birthday',
            defaultMessage: 'birthday'
          })}
          </label>
          <div className={styles.birthdayWidth}>
            <InputDate required disableValidatioOnBlur onChange={value => {
            setFormData(prev => ({
              ...prev,
              ...value
            }));
            setValidBirthdateFormat(validateDateFormat(value.birthdayDay ?? formData.birthdayDay, value.birthdayMonth ?? formData.birthdayMonth, value.birthdayYear ?? formData.birthdayYear));
          }} isFormatValid={valid => setErrors(state => ({
            ...state,
            birthdayDay: valid,
            birthdayMonth: valid,
            birthdayYear: valid
          }))} stillHasError={errors && submitTrigger && initialSend && (!errors.birthdayDay || !errors.birthdayMonth || !errors.birthdayYear)} birthdayDay={formData.birthdayDay} birthdayMonth={formData.birthdayMonth} birthdayYear={formData.birthdayYear} data-sentry-element="InputDate" data-sentry-source-file="index.tsx" />
          </div>
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm" htmlFor="email">
            {labels.labelEmail ? `${labels.labelEmail} *` : formatMessage({
            id: 'email',
            defaultMessage: 'enterMail'
          })}
          </label>
          <InputText style="white" id="email" name="email" type="email" autoComplete="email" required errorMessage={!emailErrors ? undefined : emailErrors === 'empty' ? mailErrorMessage : mailMessage} onBlur={validateEmail} onChange={handleChange} value={formData.email} wrapperCustomStyle={{
          width: '75%'
        }} inputCustomStyle={inputCustomStyle} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm">
            {labels.labelRecaptcha ? `${labels.labelRecaptcha} *` : formatMessage({
            id: 'fieldIsRequired',
            defaultMessage: 'acceptTos'
          })}
          </label>
          <span className="w-3/4">
            <ReCAPTCHA ref={recaptchaRef} sitekey={reCaptchaKey} onChange={v => setErrors(prevErrors => ({
            ...prevErrors,
            acceptReCaptcha: v !== ''
          }))} data-sentry-element="ReCAPTCHA" data-sentry-source-file="index.tsx" />
            <span className={styles.quizErrorMsg}>
              {!errors.acceptReCaptcha && initialSend && formatErrorMessage({
              id: 'fillCaptcha',
              defaultMessage: 'fillCaptcha'
            })}
            </span>
          </span>
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <span className="w-1/4"></span>
          <span className="w-3/4">
            <InputCheckbox id="accept_tos" name="acceptTos" label={<span className={styles.acceptTosWrap}>
                  <span className={errors.acceptTos === false ? styles.errorMsgColor : ''}>
                    <Markdown linkToNewTab text={labels.labelTextTos} className={styles.markdownGuidelines} />
                  </span>
                </span>} onChange={handleCheckboxChange} errorMessage={!errors.acceptTos && initialSend && formatErrorMessage({
            id: 'acceptConditionsOfParticipation'
          })} hasHoverEffect data-sentry-element="InputCheckbox" data-sentry-source-file="index.tsx" />
          </span>
        </Block>
        {newsletterOption && <Block marginBottom={4} customStyle={blockCustomStyle}>
            <span className="w-1/4"></span>
            <span className="w-3/4">
              <InputCheckbox id="newsletterAcceptions" name="wantsNewsletter" label={<Markdown text={labels.labelNewsletterOption} className={styles.markdownGuidelines} />} onChange={handleCheckboxChange} />
            </span>
          </Block>}
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <span className="w-1/4"></span>
          <span className="w-3/4">
            <InputCheckbox id="partnerConditions" name="partnerConditions" label={<Markdown text={labels.labelRaffleOriginInfos} className={styles.markdownGuidelines} />} onChange={handleCheckboxChange} data-sentry-element="InputCheckbox" data-sentry-source-file="index.tsx" />
          </span>
        </Block>
        <Block marginBottom={4} customStyle={blockCustomStyle} data-sentry-element="Block" data-sentry-source-file="index.tsx">
          <label className="w-1/4 text-sm">{formatMessage({
            id: 'mandatoryFields'
          })}</label>
          <Button size="large" label={labels.labelDesktopBtnSubmit} isLoading={loading} customStyle={{
          padding: '14px 30px',
          width: 'fit-content',
          fontSize: '17.5px'
        }} onClick={handleSubmit} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
        </Block>
      </form>
    </div>;
};