import React, { useCallback, useEffect, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';
import { InputCheckbox } from '@engbers/components';
import { IFacetProps } from './types';
import { handleFilterDataLayer } from '@frontastic-engbers/helpers/dataLayerHelper';
import ApplyFilterButton from './applyFilterButton';
import { useProductList } from '../../context';
import styles from './facets.module.scss';
const BooleanFacet: React.FC<IFacetProps> = ({
  attribute,
  label,
  onApplyFilter,
  filterOnSelection = true,
  wrapper: Wrapper = React.Fragment,
  showItemCount = false
}) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const {
    items,
    refine
  } = useRefinementList({
    attribute
  });
  const {
    updateSelectedFacets
  } = useProductList();
  const switchSelectedState = useCallback(() => {
    if (filterOnSelection) {
      refine('1');
      handleFilterDataLayer(items, label, '1');
    } else {
      setIsSelected(prev => !prev);
    }
  }, [filterOnSelection, refine, items, label]);
  useEffect(() => {
    const refined = items.some(item => item.value === '1' && item.isRefined);
    setIsSelected(refined);
  }, [items]);
  useEffect(() => {
    updateSelectedFacets({
      [attribute]: isSelected ? ['1'] : undefined
    });
  }, [updateSelectedFacets, attribute, isSelected]);
  const facetItem = items.find(term => term.value === '1');
  return <>
      <div className={styles.facetValueWrapper}>
        {facetItem && <Wrapper key={facetItem.value + 'wrapper'}>
            <div>
              <InputCheckbox id={attribute} name={attribute} checked={isSelected} onChange={switchSelectedState} label={`${label}${showItemCount ? ` (${facetItem.count})` : ``}`} />
            </div>
          </Wrapper>}
      </div>
      {!filterOnSelection && <ApplyFilterButton facets={{
      [attribute]: isSelected ? ['1'] : undefined
    }} onApplyFilters={() => onApplyFilter(attribute, label, isSelected ? ['1'] : undefined)} />}
    </>;
};
export default BooleanFacet;