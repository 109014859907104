import React from 'react';
import { OrderItem } from './components/order-item';
import { useOrderHistory } from '@frontastic-engbers/lib/actions/account';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useMediaQuery } from 'react-responsive';
import { MediaType } from '@frontastic-engbers/types/engbers-custom';
import { Block, InfoBox, LoadingIndicatorInline, MyAccountHeadline, SectionLayout, Typography } from '@engbers/components';
import styles from './order-history.module.scss';
interface IOrderHistory {
  orderStatusLabels: {
    1: string;
    2: string;
    3: string;
    4: string;
  };
  headline?: string;
  textSize?: number;
  subHeadline?: string;
  noOrdersInfo?: string;
  icon?: MediaType;
  colors?: {
    [key: string]: string;
  };
  isOS?: boolean;
}
export const OrderHistory: React.FC<IOrderHistory> = ({
  orderStatusLabels,
  headline,
  textSize,
  subHeadline,
  noOrdersInfo,
  icon,
  colors,
  isOS
}) => {
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const {
    loaded,
    orders
  } = useOrderHistory();
  const ordersWithoutRetoure = orders?.filter(order => order.type !== 'RET');
  const {
    formatMessage: formatCheckoutMessage
  } = useFormat({
    name: 'checkout'
  });
  return <>
      {headline && <Block marginBottom={8}>
          <MyAccountHeadline headlineText={headline} textSize={textSize} iconCustom="Cart" iconMedia={icon} uppercase />
        </Block>}
      <div className={styles.subHeadlineWrap}>
        <Typography weight="semi-bold" size="xl" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          {subHeadline ?? formatCheckoutMessage({
          id: 'showOrderReturn'
        })}
        </Typography>
      </div>
      {!loaded && <SectionLayout>
          <LoadingIndicatorInline color="blue" />
        </SectionLayout>}

      {loaded && ordersWithoutRetoure?.map((order, itemIndex) => {
      const retoureForThisOrder = orders?.find(o => o.type === 'RET' && o.originalId === order.id);
      return <Block className={styles.orderItemBlock} marginBottom={10} key={`item-${order.id}`}>
              <OrderItem labels={{
          orderStatus: orderStatusLabels
        }} retoure={retoureForThisOrder} isInitiallyOpen={itemIndex === 0} order={order} colors={colors} />
            </Block>;
    })}

      {loaded && !orders?.length && (isMobile ? <SectionLayout>
            <InfoBox type={isOS ? 'os' : 'default'} content={formatCheckoutMessage({
        id: 'noOrders'
      })} />
          </SectionLayout> : <Typography variant="heading-3">{noOrdersInfo}</Typography>)}
    </>;
};