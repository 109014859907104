import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { fetchApiHub, useAccount, useCart } from '@frontastic-engbers/lib';
import { handlePayment, handleRedirectPayment } from '@frontastic-engbers/lib/actions/payone';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { UseCart } from '@frontastic-engbers/lib/provider/frontastic/UseCart';
import { checkoutErrorsState, isCheckoutSubmittedState } from '@frontastic-engbers/lib/state/checkoutErrors/atoms';
import { useModalActions } from '@frontastic-engbers/lib/state/modal/actions';
import { calculateCartLineItemsPrice } from '@frontastic-engbers/helpers/dataLayerHelper/calculateCartLineItemsPrice';
import { joinDiscounts } from '@frontastic-engbers/helpers/dataLayerHelper/couponCodeDataHelper';
import { updateItemDatalayerPush } from '@frontastic-engbers/helpers/dataLayerHelper/updateItemDatalayerHelper';
import { get3dsInformation } from '@frontastic-engbers/helpers/utils/get3dsInformation';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { CheckoutData, CheckoutError, IFTLinkReference, IFTPageFolderReference, IPaymentMethod, MediaType, PaymentErrors, SoldOutVariants } from '@frontastic-engbers/types/engbers-custom';
import { AccountUpdateBody } from '@frontastic-engbers/types/account/Account';
import { Discount } from '@frontastic-engbers/types/cart/Discount';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { Markdown } from '@engbers/components';
import Spinner from '@engbers/components/online-shops/commercetools-ui/spinner';
import { ISizeVariant } from '../flyout-cart/cart-line-items';
import { Section } from './section';
import { BillingAddress } from './section/billing-address';
import { ShippingAddress } from './section/shipping-address';
import { Payment } from './section/payment';
import { RedirectModal } from './section/payment/redirect-modal';
import { Overview } from './section/overview';
import { useCheckout } from './context/CheckoutDataProvider';
import { usePayments } from './context/PaymentProvider';
import { useValidateAndRegisterUserCallback } from './hooks/useValidateUserDataCallback';
import { mapToCartStructure } from './utils/mapFormData';
import { requiredDataIsValid, validateBillingAddress, validateCustomerData, validatePayment, validateShippingAddress } from './utils/requiredDataIsValid';
import { SECURE_PAYMENTS } from './utils/PaymentDecisionMaker';
import styles from './checkout.module.scss';
import { containsExcludedChars } from '@frontastic-engbers/helpers/utils/webserviceExcludedChars';
interface ICheckout {
  headlines: {
    existing: string;
    new: string;
    guest: string;
    textSize?: number;
  };
  addressLabels: {
    radioMister: string;
    radioMs: string;
    labelFirstName: string;
    labelLastName: string;
    labelStreet: string;
    labelHouse: string;
    labelCompany: string;
    labelZip: string;
    labelCity: string;
    labelCountry: string;
    labelBirthdate: string;
    labelEmail: string;
    labelEmailRepeat: string;
    labelPassword: string;
    labelPasswordRepeat: string;
    labelAgb: string;
    labelPostActions: string;
    labelCardAgb: string;
    invalidMailFormat: string;
  };
  billingAddressSection: {
    headline: string;
    migrationVerifyInformation: string;
    migrationVerifyBirthdate: string;
  };
  shippingSection: {
    headline: string;
    hasStoreDelivery: boolean;
    hasPackStationDelivery: boolean;
    billingAddressBoxIcon: MediaType;
    billingAddressBoxLabel: string;
    shippingAddressBoxIcon: MediaType;
    shippingAddressBoxLabel: string;
    storeDeliveryBoxIcon: MediaType;
    storeDeliveryBoxLabel: string;
    packStationBoxIcon: MediaType;
    packStationBoxLabel: string;
  };
  shippingAddressSection: {
    headlineShippingAddress: string;
    textShippingAddress: string;
  };
  packStationSection: {
    headlinePackstation: string;
    labelPostNo: string;
    labelSelectPackstation: string;
    textPackstation: string;
    errorPackstation: string;
    errorPostNo: string;
  };
  storeDeliverySection: {
    headlineStoreDelivery: string;
    textStoreDelivery: string;
    labelOpeningHours: string;
    labelSelectStoreDelivery: string;
    errorStoreDelivery: string;
    storeClosed: string;
  };
  paymentSection: {
    headline: string;
    paymentMethods: IPaymentMethod[];
    paymentHintForGuest?: string;
    paymentHintLockedProducts?: string;
    paymentError: string;
    paymentDeniedError: string;
    paymentCreditCardAuthorized: string;
    paymentRedirectHeadline: string;
    paymentRedirectInfoText: string;
    paymentRedirectButtonLabel: string;
    solvencyFailedControlMessage: string;
    solvencyPositiveMessage: string;
    solvencyFailedMessage: string;
    solvencyInvoiceMessage: string;
    solvencyDebitMessage: string;
    solvencyModalTitle: string;
    solvencyModalTitleProgess: string;
    solvencyModalCancelLabel: string;
    solvencyModalSubmitLabel: string;
  };
  overviewSection: {
    headline: string;
    overviewBillingAddressHeadline: string;
    overviewShippingAddressHeadline: string;
    overviewDeliveryTimeHeadline: string;
    overviewPaymentHeadline: string;
    overviewDifferentSizeMessage: string;
  };
  forbiddenFields: {
    fieldValidationMessage: string;
    forbiddenFieldList: string;
  };
  voucherSection: {
    voucherHeadline: string;
    voucherInfo: string;
    voucherRedeemBtn: string;
    voucherInvalidLabel: string;
    voucherRedeemedLabel: string;
  };
  productOverviewSection: {
    productOverviewArticle: string;
    productOverviewDesc: string;
    productOverviewAmount: string;
    productOverviewSum: string;
    pathSizeSuggestion?: IFTLinkReference | IFTPageFolderReference;
  };
  totalSummarySection: {
    commentOnOrderLabel: string;
    shippingCostsNote: string;
    showCommentOnOrderLabel: boolean;
  };
  ctaSection: {
    submitBtnLabel: string;
    enNewsletterLabel: string;
    emilioNewsletterLabel: string;
    xxlNewsletterLabel: string;
  };
}
export const Checkout: React.FC<ICheckout> = ({
  headlines,
  addressLabels,
  billingAddressSection,
  shippingSection,
  shippingAddressSection,
  packStationSection,
  storeDeliverySection,
  paymentSection,
  overviewSection,
  forbiddenFields,
  voucherSection,
  productOverviewSection,
  totalSummarySection,
  ctaSection
}) => {
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const {
    loggedIn,
    account,
    loaded,
    update: updateAccount
  } = useAccount();
  const router = useRouter();
  const validateAndRegisterUser = useValidateAndRegisterUserCallback();
  const {
    pushModal
  } = useModalActions();
  const {
    data: checkoutData,
    updateFormInput,
    customerOrigin,
    customerOriginDetermined
  } = useCheckout();
  const {
    hostedTokenizationId,
    paymentProductId,
    resetPayment,
    checkSolvency
  } = usePayments();
  const {
    data: cart,
    addItem,
    removeItem,
    updateItem,
    updateCart,
    checkout,
    removeDiscountCode,
    undoRefuseGift
  } = useCart();
  const billingAddressSectionRef = useRef<HTMLDivElement>(null);
  const shippingSectionRef = useRef<HTMLDivElement>(null);
  const paymentSectionRef = useRef<HTMLDivElement>(null);
  const overviewSectionRef = useRef<HTMLDivElement>(null);
  const handledInitialCall = useRef<boolean>(false);
  const handleDatalayer = useRef<boolean>(false);
  const checkedEmptyCart = useRef<boolean>(false);
  const [billingAddressPrefilled, setBillingAddressPrefilled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [discounts, setDiscounts] = useState<Discount[]>([]);
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const [dataIsValid, setDataIsValid] = useState<boolean>(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const [checkoutErrors, setCheckoutErrors] = useRecoilState(checkoutErrorsState);
  const [paymentErrors, setPaymentErrors] = useState<Partial<PaymentErrors>>({});
  const [isCheckoutSubmitted] = useRecoilState(isCheckoutSubmittedState);
  const [ctaCheckoutError, setCtaCheckoutError] = useState<CheckoutError>(null);
  const [datalayerItems, setDatalayerItems] = useState([]);
  const [datalayerCouponCodes, setDatalayerCouponCodes] = useState('');
  const handleLineItemUpdate = async (fn: () => Promise<boolean>) => {
    setLoading(true);
    const continueLoading = await fn();
    setLoading(continueLoading);
  };
  const updateItemQuantity = (lineItem: LineItem, newQuantity: number) => {
    updateItemDatalayerPush(lineItem, newQuantity);
    return handleLineItemUpdate(async () => {
      await updateItem(lineItem.lineItemId, newQuantity);
      return false;
    });
  };
  const updateItemSize: UseCart['addItem'] = (variant, quantity, shouldMutate) => handleLineItemUpdate(async () => {
    await addItem(variant, quantity, shouldMutate);
    return !shouldMutate;
  });
  const deleteItem = (lineItem: LineItem) => {
    const total = Number(((lineItem.totalPrice.centAmount ?? 0) / 10 ** lineItem.price.fractionDigits).toFixed(lineItem.price.fractionDigits));
    new TagManager().removeFromCartEvent([ItemMapper.lineItemToItem(lineItem, lineItem.count)], total).executePush();
    return handleLineItemUpdate(async () => {
      await removeItem(lineItem.lineItemId);
      return false;
    });
  };
  const addRefusedGift: UseCart['undoRefuseGift'] = async refusedGift => {
    setLoading(true);
    await undoRefuseGift(refusedGift);
    setLoading(false);
  };
  const stripSkus = (skus: string[]) => {
    return skus.map(sku => getSKUParts(sku).key);
  };
  const updateCartData = async (validate = false, checkoutDataWithPayment?: CheckoutData) => {
    const isValid = await requiredDataIsValid(checkoutDataWithPayment || checkoutData, hostedTokenizationId, !loggedIn ? customerOrigin : undefined);
    if (!validate || isValid) {
      const updatedData = mapToCartStructure(checkoutDataWithPayment || checkoutData, customerOrigin);
      await updateCart(updatedData);
    }
  };
  const updateAccountData = async () => {
    const billingAddress = checkoutData.billingAddress;
    const updatedAccountBody: AccountUpdateBody = {
      email: account.email,
      salutation: billingAddress.salutation,
      firstName: billingAddress.firstName,
      lastName: billingAddress.lastName,
      addressStreetName: billingAddress.streetName,
      addressCity: billingAddress.city,
      addressPostalCode: billingAddress.postalCode,
      addressStreetNumber: billingAddress.streetNumber,
      addressAdditionalAddress: billingAddress.additionalAddressInfo,
      addressCountry: billingAddress.country,
      phone: billingAddress.phone
    };
    await updateAccount(updatedAccountBody, true);
  };
  const checkAvailibility = async () => {
    let escape = false;
    const variants: SoldOutVariants[] = [];
    const skus: string[] = [];
    for (const lineItem of cart.lineItems) {
      const skuParts = getSKUParts(lineItem.variant.sku);
      const response: ISizeVariant[] = await fetchApiHub(`/action/stock/getVariantsStockInfo?key=${skuParts.key}&onlyInStock=false`);
      const size = response.find(size => size.sku == lineItem.variant.sku);
      if (size && size.availableQuantity < lineItem.count) {
        variants.push({
          sku: lineItem.variant.sku,
          soldOut: size.availableQuantity == 0,
          availableQuantity: size.availableQuantity
        });
        skus.push(lineItem.variant.sku);
        escape = true;
      }
    }
    if (escape === true) {
      setCtaCheckoutError({
        code: 'OutOfStock',
        skus: stripSkus(skus),
        variants
      });
      setLoading(false);
    }
    return escape;
  };
  const handleSubmit = async () => {
    const filteredErrors = checkoutErrors ? Object.keys(checkoutErrors).filter(key => !checkoutErrors[key].isValid) : null;
    if (!dataIsValid || disableSubmitButton || filteredErrors?.length) {
      return;
    }
    setCtaCheckoutError(null);
    setLoading(true);
    if (cart.lineItems.length > 0) {
      const foundOutOfStock = await checkAvailibility();
      if (foundOutOfStock) {
        return;
      }
    }
    try {
      await updateCartData(true);
      if (!(await validateAndRegisterUser(checkoutData, billingAddressSectionRef.current, updateFormInput, billingAddressSection.migrationVerifyInformation, billingAddressSection.migrationVerifyBirthdate, customerOrigin.isGuest || loggedIn, loading, checkoutErrors, customerOrigin))) {
        setLoading(false);
        return;
      }
      if (!loading) {
        setLoading(true);
      }
      const method = paymentSection.paymentMethods?.find(method => method.paymentMethodType === checkoutData.paymentMethod);
      if (loggedIn || customerOrigin.isNew) {
        if (!(method.paymentMethodType in SECURE_PAYMENTS)) {
          const isValid = await checkSolvency();
          if (!isValid) {
            await onPaymentError('solvencyError');
            return;
          }
        }
        if (account) {
          await updateAccountData();
        }
      }
      const {
        isAuthorized,
        redirectUrl
      } = await handlePayment(method, customerOrigin, hostedTokenizationId, paymentProductId, get3dsInformation(), account?.accountId);
      if (redirectUrl) {
        setLoading(false);
        pushModal({
          id: 'redirect-payment-modal',
          title: paymentSection.paymentRedirectHeadline,
          content: <RedirectModal redirectUrl={redirectUrl} infoText={paymentSection.paymentRedirectInfoText} label={paymentSection.paymentRedirectButtonLabel} />,
          isMedium: true,
          hasCloseButton: false,
          canCloseByBackdropClick: false
        });
        return;
      }
      if (!isAuthorized) {
        await onPaymentError();
        return;
      }
      await checkoutSubmit();
    } catch (e) {
      console.info('Order submit failed', e);
    }
  };
  const checkoutSubmit = async () => {
    const response = await checkout();
    if (!response.checkoutError) {
      router.push('/thank-you');
      return;
    }
    if (response.checkoutError.code === 'OutOfStock' && response.checkoutError.skus.length > 0) {
      await setSoldOutErrors(response.checkoutError.skus, response.checkoutError.code);
    } else {
      setCtaCheckoutError({
        code: response.checkoutError.code
      });
    }
    setLoading(false);
    overviewSectionRef.current.scrollIntoView({
      behavior: 'smooth'
    });
  };
  const setSoldOutErrors = async (skus: string[], code: string) => {
    const variants: SoldOutVariants[] = [];
    for (const sku of skus) {
      const skuParts = getSKUParts(sku);
      const response: ISizeVariant[] = await fetchApiHub(`/action/stock/getVariantsStockInfo?key=${skuParts.key}&onlyInStock=false`);
      const size = response.find(size => size.sku == sku);
      if (size) {
        variants.push({
          sku,
          soldOut: size.availableQuantity == 0,
          availableQuantity: size.availableQuantity
        });
      }
    }
    setCtaCheckoutError({
      code,
      skus: stripSkus(skus),
      variants
    });
  };
  const onPaymentError = async (errorType: keyof PaymentErrors = 'paymentDenied') => {
    resetPayment();
    setPaymentErrors(errors => ({
      ...errors,
      [errorType]: true
    }));
    paymentSectionRef.current?.scrollIntoView({
      behavior: 'smooth'
    });
    setLoading(false);
  };
  const updateEcondaDataLayer = (ecContent: string, ecOrderProcess: string) => {
    const econdaDataLayer = JSON.parse(sessionStorage.getItem('econda_data_layer'));
    if (econdaDataLayer) {
      econdaDataLayer.ecContent = ecContent;
      econdaDataLayer.ecOrderProcess = ecOrderProcess;
      sessionStorage.setItem('econda_data_layer', JSON.stringify(econdaDataLayer));
      new TagManager().updateEcondaDataEvent(econdaDataLayer).executePush();
    }
  };
  const headline = useMemo(() => {
    if (customerOrigin.isGuest) {
      return headlines.guest;
    }
    if (loggedIn || checkoutData.customerData.isMigrationUser) {
      return headlines.existing;
    }
    return headlines.new;
  }, [customerOrigin.isGuest, loggedIn, checkoutData.customerData.isMigrationUser]);
  useEffect(() => {
    if (checkedEmptyCart.current || !cart || !handledInitialCall.current) {
      return;
    }
    checkedEmptyCart.current = true;
    const path = router.asPath;
    if (path.includes('/checkout') && !cart.lineItems?.length) {
      router.push('/');
    }
  }, [router.query.path, router, cart, handledInitialCall.current]);
  useEffect(() => {
    if (!cart || handledInitialCall.current || !customerOriginDetermined || loggedIn && !billingAddressPrefilled) {
      return;
    }
    const {
      paymentId,
      hostedCheckoutId
    } = router.query;
    const getPaymentResult = async (paymentId: string) => {
      const isAuthorized = await handleRedirectPayment(paymentId);
      if (!isAuthorized) {
        await onPaymentError();
        return;
      }
      await checkoutSubmit();
    };
    if (paymentId || hostedCheckoutId) {
      setLoading(true);
      getPaymentResult((paymentId ?? hostedCheckoutId) as string);
      handledInitialCall.current = true;
      return;
    }
    const updateInitialCartData = async () => {
      updateFormInput('commentOnOrder', '');
      await updateCartData(false, {
        ...checkoutData,
        commentOnOrder: ''
      });
      handledInitialCall.current = true;
    };
    updateInitialCartData();
  }, [cart, customerOriginDetermined, billingAddressPrefilled]);
  useEffect(() => {
    const validate = async () => {
      setDataIsValid(await requiredDataIsValid(checkoutData, hostedTokenizationId, !loggedIn ? customerOrigin : undefined));
      setCheckoutErrors({
        billingAddress: {
          isValid: loggedIn ? validateBillingAddress(checkoutData) : validateBillingAddress(checkoutData) && (await validateCustomerData(checkoutData, customerOrigin)),
          section: billingAddressSectionRef.current
        },
        shippingAddress: {
          isValid: checkoutData.shippingMethod === 'shippingAddress' ? !!validateShippingAddress(checkoutData) : true,
          section: shippingSectionRef.current
        },
        payment: {
          isValid: validatePayment(checkoutData, hostedTokenizationId),
          section: paymentSectionRef.current
        },
        userAgbAccepted: {
          isValid: loggedIn ? checkoutData.userAgbAccepted : true
        },
        commentOnOrder: {
          isValid: !containsExcludedChars(checkoutData.commentOnOrder)
        }
      });
    };
    validate();
  }, [checkoutData, loaded, isCheckoutSubmitted, hostedTokenizationId]);
  useEffect(() => {
    if (!customerOriginDetermined) {
      return;
    }
    if (loaded) {
      updateEcondaDataLayer(`Shop/Kaufprozess/Kundendaten/${loggedIn ? 'BereitsKunde' : customerOrigin.isGuest ? 'OhneReg' : 'NeuesKonto'}`, `2_Kundendaten/${loggedIn ? 'BereitsKunde' : customerOrigin.isGuest ? 'OhneReg' : 'NeuesKonto'}`);
    }
    if (checkoutErrors?.billingAddress?.isValid && !checkoutErrors?.payment?.isValid || loaded && loggedIn && !checkoutData.paymentMethod) {
      updateEcondaDataLayer('Shop/Kaufprozess/Zahlungsoptionen', '3_Zahlungsoptionen');
    }
    if (loaded && checkoutErrors?.billingAddress?.isValid && checkoutErrors?.payment?.isValid && checkoutErrors?.userAgbAccepted?.isValid) {
      updateEcondaDataLayer('Shop/Kaufprozess/Bestelluebersicht', '4_Bestelluebersicht');
    }
  }, [loggedIn, customerOriginDetermined]);
  useEffect(() => {
    if (!customerOriginDetermined) {
      return;
    }
    const loginValue = loggedIn ? 'Bestandskunde' : customerOrigin.isGuest ? 'Gastbesteller' : 'Neukunde';
    new TagManager().customEvent('login', {
      value: loginValue
    }).executePush();
  }, [loggedIn, customerOriginDetermined]);
  useEffect(() => {
    const filteredErrors = checkoutErrors ? Object.keys(checkoutErrors).filter(key => !checkoutErrors[key].isValid) : null;
    setDisableSubmitButton(!(dataIsValid && !filteredErrors?.length));
  }, [dataIsValid, checkoutErrors]);
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  useEffect(() => {
    if (!cart) {
      return;
    }
    setDatalayerItems(cart?.lineItems?.map((lineItem: LineItem, index) => ItemMapper.lineItemToItem(lineItem, lineItem.count, index, lineItem.variant, true)));
    setDatalayerCouponCodes(joinDiscounts(cart?.discountCodes, cart?.customLineItems));
  }, [cart]);
  useEffect(() => {
    if (!cart || handleDatalayer.current) {
      return;
    }
    if (datalayerItems?.length > 0) {
      new TagManager().ecommerceEvent('add_shipping_info', datalayerItems, calculateCartLineItemsPrice(cart) ?? cart.sum, datalayerCouponCodes, {
        shipping_tier: checkoutData.shippingMethod
      }).executePush();
      handleDatalayer.current = true;
    }
  }, [cart]);
  useEffect(() => {
    if (datalayerItems?.length > 0) {
      new TagManager().ecommerceEvent('add_shipping_info', datalayerItems, calculateCartLineItemsPrice(cart) ?? cart.sum, datalayerCouponCodes, {
        shipping_tier: checkoutData.shippingMethod
      }).executePush();
    }
  }, [checkoutData.shippingMethod]);
  useEffect(() => {
    if (datalayerItems?.length > 0 && checkoutData.paymentMethod !== null) {
      new TagManager().ecommerceEvent('add_payment_info', datalayerItems, calculateCartLineItemsPrice(cart) ?? cart.sum, datalayerCouponCodes, {
        payment_type: checkoutData.paymentMethod
      }).executePush();
    }
  }, [checkoutData.paymentMethod]);
  return <div className={styles.wrapper} data-sentry-component="Checkout" data-sentry-source-file="index.tsx">
      {loading ? <div className={styles.spinnerWrap}>
          <Spinner size={isMinWidthMedium ? 'small' : 'large'} />
        </div> : null}
      <Markdown className={styles.title} text={headline} textSize={headlines.textSize} data-sentry-element="Markdown" data-sentry-source-file="index.tsx" />
      <Section elementRef={billingAddressSectionRef} headline={billingAddressSection.headline} data-sentry-element="Section" data-sentry-source-file="index.tsx">
        <BillingAddress updateCartData={updateCartData} labels={addressLabels} forbiddenFields={forbiddenFields} setBillingAddressPrefilled={setBillingAddressPrefilled} setCheckoutErrors={setCheckoutErrors} setLoading={setLoading} data-sentry-element="BillingAddress" data-sentry-source-file="index.tsx" />
      </Section>
      <Section elementRef={shippingSectionRef} headline={shippingSection.headline} data-sentry-element="Section" data-sentry-source-file="index.tsx">
        <ShippingAddress shippingSection={shippingSection} updateCartData={updateCartData} labels={addressLabels} shippingAddressSection={shippingAddressSection} packStationSection={packStationSection} storeDeliverySection={storeDeliverySection} setLoading={setLoading} loading={loading} forbiddenFields={forbiddenFields} checkoutErrors={checkoutErrors} setCheckoutErrors={setCheckoutErrors} data-sentry-element="ShippingAddress" data-sentry-source-file="index.tsx" />
      </Section>
      <Section elementRef={paymentSectionRef} headline={paymentSection.headline} data-sentry-element="Section" data-sentry-source-file="index.tsx">
        <Payment updateCartData={updateCartData} loading={loading} setLoading={setLoading} paymentSection={paymentSection} billingAddressSectionRef={billingAddressSectionRef.current} paymentSectionRef={paymentSectionRef.current} overviewSectionRef={overviewSectionRef.current} migrationVerifyInformation={billingAddressSection.migrationVerifyInformation} migrationVerifyBirthdate={billingAddressSection.migrationVerifyBirthdate} billingAddressPrefilled={billingAddressPrefilled} checkoutErrors={checkoutErrors} paymentErrors={paymentErrors} handledInitialCall={handledInitialCall.current} setPaymentErrors={setPaymentErrors} data-sentry-element="Payment" data-sentry-source-file="index.tsx" />
      </Section>
      <Section elementRef={overviewSectionRef} headline={overviewSection.headline} data-sentry-element="Section" data-sentry-source-file="index.tsx">
        <Overview overviewSection={overviewSection} billingAddressSectionRef={billingAddressSectionRef.current} shippingSectionRef={shippingSectionRef.current} paymentSectionRef={paymentSectionRef.current} overviewSectionRef={overviewSectionRef.current} voucherSection={{
        ...voucherSection,
        discounts: discounts,
        setDiscounts: setDiscounts
      }} productOverviewSection={productOverviewSection} cartFunctions={{
        updateItemQuantity: updateItemQuantity,
        updateItemSize: updateItemSize,
        deleteItem: deleteItem,
        removeDiscountCode: removeDiscountCode,
        setIsUpdating: setLoading,
        addRefusedGift: addRefusedGift
      }} totalSummarySection={totalSummarySection} disableSubmitButton={disableSubmitButton} onSubmit={handleSubmit} checkoutErrors={checkoutErrors} ctaSection={{
        ...ctaSection,
        labelAgb: addressLabels.labelAgb,
        ctaCheckoutError
      }} setLoading={setLoading} data-sentry-element="Overview" data-sentry-source-file="index.tsx" />
      </Section>
    </div>;
};