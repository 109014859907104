import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useCart } from '@frontastic-engbers/lib';
import { useShippingProvider } from '@frontastic-engbers/lib/actions/config';
import { useFormat } from "@frontastic-engbers/helpers/hooks/useFormat";
import { getFormattedShippingCostsByCountry } from '@frontastic-engbers/helpers/utils/getShippingInfo';
import { IconCustom, Image } from '@engbers/components';
import { useCheckout } from '../context/CheckoutDataProvider';
import styles from './shipping-provider.module.scss';
import addressStyles from '../section/shipping-address/shipping-address.module.scss';
import checkoutStyles from '../checkout.module.scss';
import { CurrencyHelpers } from '@frontastic-engbers/helpers/currencyHelpers';
interface IShippingProviderSection {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
export const ShippingProviderSection: React.FC<IShippingProviderSection> = ({
  setLoading
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'checkout'
  });
  const {
    shippingProvider
  } = useShippingProvider();
  const {
    data: cart,
    setShippingMethod
  } = useCart();
  const {
    data: checkoutData
  } = useCheckout();
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const [showInfo, setShowInfo] = useState<string | null>(null);
  const [clickedInfo, setClickedInfo] = useState<string | null>(null);
  const selectShippingProvider = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const shippingProviderId = e.target.value;
    if (e.target.checked) {
      setLoading(true);
      try {
        await setShippingMethod(shippingProviderId);
      } catch (error) {
        console.error('Error selecting shipping provider:', error);
      } finally {
        setLoading(false);
      }
    }
  }, [setLoading, setShippingMethod]);
  const methodMap = useMemo(() => {
    if (!cart?.availableShippingMethods) {
      return new Map();
    }
    return new Map(cart.availableShippingMethods.map(method => [method.shippingMethodId, method]));
  }, [cart?.availableShippingMethods]);
  const filteredProviders = useMemo(() => {
    if (!shippingProvider || methodMap.size === 0) {
      return [];
    }
    return shippingProvider.filter(provider => methodMap.has(provider.shippingCompany)).map(provider => {
      const shippingMethod = methodMap.get(provider.shippingCompany);
      const price = (cart.shippingInfo?.discountedPrice?.centAmount && CurrencyHelpers.formatForCurrency(cart.shippingInfo?.discountedPrice?.centAmount, true)) ?? getFormattedShippingCostsByCountry(shippingMethod, cart?.shippingAddress?.country);
      const subtext = cart?.shippingAddress.country?.toLowerCase() === 'de' ? provider.subtext.replace('{days}', provider.deliveryTimeDE) : provider.subtext.replace('{days}', provider.deliveryTimeEU);
      return {
        ...provider,
        shippingMethod,
        price,
        subtext
      };
    });
  }, [shippingProvider, methodMap, cart?.shippingInfo?.discountedPrice?.centAmount, cart?.shippingAddress.country]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (clickedInfo && !(event.target as Element).closest(`.${checkoutStyles.infoWrap}`)) {
        setClickedInfo(null);
        setShowInfo(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [clickedInfo, checkoutStyles.infoWrap]);
  if ((shippingProvider?.length ?? 0) < 2 || (cart?.availableShippingMethods?.length ?? 0) < 2 || checkoutData.shippingMethod !== 'billingAddress' && checkoutData.shippingMethod !== 'shippingAddress') {
    return null;
  }
  return <div className={addressStyles.addressFormWrap} style={isMobile ? {
    order: checkoutData.shippingMethod === 'billingAddress' ? 0 : 1
  } : undefined} data-sentry-component="ShippingProviderSection" data-sentry-source-file="index.tsx">
      {filteredProviders.map(provider => {
      const isChecked = cart?.shippingInfo?.shippingMethodId === provider.shippingCompany;
      const label = formatMessage({
        id: `ariaLabel.selectShippingProvider`,
        values: {
          provider: provider.name
        }
      });
      return <label key={provider.shippingCompany} className={classNames(styles.shippingWrapper, {
        [styles.isChecked]: isChecked
      })} htmlFor={provider.name} aria-label={label} tabIndex={0} onKeyDown={e => {
        if (e.key === 'Enter') {
          e.currentTarget?.click();
        }
      }}>
            <input id={provider.name} name={provider.name} value={provider.shippingCompany} className="sr-only" type="checkbox" checked={isChecked} onChange={selectShippingProvider} />
            {provider.information && <button className={checkoutStyles.infoWrap} onMouseEnter={() => setShowInfo(provider.shippingCompany)} onMouseLeave={() => !clickedInfo && setShowInfo(null)} onClick={() => {
          if (clickedInfo === provider.shippingCompany) {
            setClickedInfo(null);
            setShowInfo(null);
          } else {
            setClickedInfo(provider.shippingCompany);
            setShowInfo(provider.shippingCompany);
          }
        }} aria-label={formatMessage({
          id: 'ariaLabel.openShippingProviderInfo'
        })}>
                <IconCustom width={18} color="white" icon="Info" />
                <div className={classNames(checkoutStyles.infoText, {
            [checkoutStyles.showInfo]: provider.shippingCompany === showInfo || provider.shippingCompany === clickedInfo
          })}>
                  <div className={checkoutStyles.infoTextInner}>{provider.information}</div>
                </div>
              </button>}
            <div className={styles.shippingProvider}>
              <Image src={provider.logo.media.file} alt={provider.name} />
              <div className={styles.textWrapper}>
                <span className={styles.title}>
                  {provider.text} {(provider?.price && parseInt(provider.price.replace('€', ''))) > 0 && `(${provider.price})`}
                </span>
                <div className={styles.subtext}>
                  <span>{provider.subtext}</span>
                </div>
              </div>
            </div>
          </label>;
    })}
    </div>;
};