import React from 'react';
import { AutocompletePlugin, getAlgoliaResults } from '@algolia/autocomplete-js';
import { SearchResponse } from '@algolia/client-search';
import { SearchClient } from 'algoliasearch/dist/algoliasearch-lite';
import { PageMapper } from '@frontastic-engbers/lib/lib/mappers/pageMapper';
import { HitType } from '@frontastic-engbers/types/product/Hit';
import { SuggestedCategory } from '../suggested-category';
import { debounce } from '@frontastic-engbers/helpers/algoliaSearchRequestDebouncer';
export const categoriesPlugin = (searchClient: SearchClient, algoliaConfig: any, suggestedCategoriesLimit: number, onClick: () => void) => {
  const categoryIndexName = algoliaConfig.dataSource.indexName.replace('products_', 'pages_');
  const debouncedSearch = debounce(async (query: string, setContext: (context: any) => void, state: any) => {
    if (query.length < (algoliaConfig?.dataSource?.triggerSearchRequestOn ?? 3)) {
      return [];
    }
    const results = getAlgoliaResults({
      searchClient,
      queries: [{
        indexName: categoryIndexName,
        query,
        params: {
          attributesToRetrieve: ['name', 'link', 'breadcrumbs'],
          hitsPerPage: state.context.showAllCategories ? suggestedCategoriesLimit : 3
        }
      }],
      transformResponse({
        hits,
        results
      }) {
        setContext({
          nbCategories: (results[0] as SearchResponse).nbHits
        });
        return hits;
      }
    });
    return results || [];
  }, algoliaConfig?.dataSource?.searchRequestDelay ?? 500);
  return {
    getSources({
      setQuery,
      refresh
    }) {
      return [{
        sourceId: 'categories',
        async getItems({
          query,
          setContext,
          state
        }) {
          return debouncedSearch(query, setContext, state);
        },
        onSelect() {
          onClick();
          setQuery('');
          refresh();
        },
        templates: {
          item({
            item,
            state
          }) {
            const category = PageMapper.algoliaHitToSuggestedPage(item);
            return <SuggestedCategory key={`suggested-category-${item.objectID}`} category={category} query={state.query} />;
          }
        }
      }];
    }
  } as AutocompletePlugin<HitType, {}>;
};