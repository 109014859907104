import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { getReferenceTarget } from '@frontastic-engbers/helpers/reference';
import { getProjectInfo } from '@frontastic-engbers/lib/provider';
import { IFTLinkReference, IFTPageFolderReference, TMediaItem } from '@frontastic-engbers/types/engbers-custom';
import { BackButton, Block, Button, EmailVerification, Link, LoginForm, Markdown, MigrationLoginForm, Typography } from '@engbers/components';
import { FullPageWidthWrapper } from '@engbers/components/online-shops';
import styles from './login-page.module.scss';
interface ILoginPageData {
  emailInputPlaceholder?: string;
  emailTooltipContent?: string;
  emailTooltipHeadline?: string;
  firstTimeInShopLabel?: string;
  firstTimeInShopLink?: IFTLinkReference | IFTPageFolderReference;
  homepageRedirectLink?: IFTLinkReference | IFTPageFolderReference;
  doiNotificationLink?: IFTLinkReference | IFTPageFolderReference;
  loginSectionTitle?: string;
  loginTextSize?: number;
  loginDesktopTitleAlign: 'left' | 'center' | 'right';
  loginMobileTitleAlign: 'left' | 'center' | 'right';
  logo?: TMediaItem;
  logoWidth?: string;
  migrationButtonLabel?: string;
  migrationHidden?: boolean;
  migrationEmailPlaceholder?: string;
  migrationEmailTooltipContent?: string;
  migrationEmailTooltipHeadline?: string;
  migrationLoginSectionTitle?: string;
  migrationTextSize?: number;
  migrationDesktopTitleAlign: 'left' | 'center' | 'right';
  migrationMobileTitleAlign: 'left' | 'center' | 'right';
  migrationFirstTimeInShopLabel?: string;
  migrationVerifyInformation?: string;
  migrationVerifyBirthdate?: string;
  passwordForgotLinkLabel?: string;
  passwordForgotLink?: IFTLinkReference | IFTPageFolderReference;
  passwordForgottenTooltipContent?: string;
  passwordForgottenTooltipHeadline?: string;
  passwordInputPlaceholder?: string;
  passwordTooltipContent?: string;
  passwordTooltipHeadline?: string;
  loginButtonLabel?: string;
  redirectLink?: IFTLinkReference | IFTPageFolderReference;
  registerLink?: IFTLinkReference | IFTPageFolderReference;
  registrationBenefitsLink?: IFTLinkReference | IFTPageFolderReference;
  registrationBenefitsLinkLabel?: string;
  registrationButtonLabel?: string;
  registrationCTATargetLink?: IFTLinkReference | IFTPageFolderReference;
  registrationSectionTitle?: string;
  registrationTextSize?: number;
  registrationDesktopTitleAlign: 'left' | 'center' | 'right';
  registrationMobileTitleAlign: 'left' | 'center' | 'right';
  subtitle?: string;
  title?: string;
  guestSectionTitle?: string;
  guestTextSize?: number;
  guestMobileTitleAlign: 'left' | 'center' | 'right';
  guestDesktopTitleAlign: 'left' | 'center' | 'right';
  guestButtonLabel?: string;
  guestInfoText?: string;
  registrationInitiallyOpen?: boolean;
  loginInitiallyOpen?: boolean;
  migrationInitiallyOpen?: boolean;
  guestCheckoutVisible?: boolean;
  isFullWidth?: boolean;
  projectInfo: {
    name: string;
    projectId: string;
  };
}
interface ILoginPageTasticComponent {
  data: ILoginPageData;
}
export const LoginPageTastic: React.FC<ILoginPageTasticComponent> = ({
  data
}) => {
  const router = useRouter();
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const isCheckout = router.asPath === '/checkout/login';
  const projectInfo = getProjectInfo();
  const isEmilio = projectInfo.projectId === 'ea';
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  const onRegisterClickCallback = useCallback(() => {
    if (isCheckout) {
      router.push({
        pathname: getReferenceTarget(data.registrationCTATargetLink),
        query: {
          isNew: true
        }
      });
    } else {
      router.push(getReferenceTarget(data.registrationCTATargetLink));
    }
  }, [router, data.registrationCTATargetLink]);
  const onGuestCheckoutClickCallback = useCallback(() => {
    router.push({
      pathname: '/checkout',
      query: {
        isGuest: true
      }
    });
  }, [router]);
  const content = <div className={styles.loginWrapper}>
      <div className={styles.emailVerificationWrapper}>
        <EmailVerification redirectLink={data.homepageRedirectLink} usePushNotification={true} tokenParam="doiId" />
      </div>

      <div className={styles.loginBoxWrapper}>
        <div style={{
        paddingTop: isMinWidthMedium ? 0 : undefined
      }} className={styles.loginBox}>
          <div style={{
          position: 'relative'
        }}>
            {isMinWidthMedium ? <BackButton classNames={styles.loginBackBtn} /> : null}
            <Markdown className={styles.markdownHeadline} text={data.loginSectionTitle} textAlign={isMinWidthMedium ? data.loginMobileTitleAlign : data.loginDesktopTitleAlign} textSize={data.loginTextSize} />
          </div>
          <LoginForm labels={data} homepageRedirectLink={data.homepageRedirectLink} firstTimeInShopLink={data.firstTimeInShopLink} passwordForgotLink={data.passwordForgotLink} doiNotificationLink={data.doiNotificationLink} isEngbersOS={true} />
        </div>

        {!data.migrationHidden && <div className={styles.loginBox}>
            <Markdown text={data.migrationLoginSectionTitle} textAlign={isMinWidthMedium ? data.migrationMobileTitleAlign : data.migrationDesktopTitleAlign} textSize={data.migrationTextSize} />
            <MigrationLoginForm {...data} isEngbersOS={true} />
            {data.migrationFirstTimeInShopLabel && <Typography>{data.migrationFirstTimeInShopLabel}</Typography>}
          </div>}
      </div>

      <div className={styles.loginBoxWrapper}>
        <div className={styles.loginBox}>
          <Markdown text={data.registrationSectionTitle} textAlign={isMinWidthMedium ? data.registrationMobileTitleAlign : data.registrationDesktopTitleAlign} textSize={data.registrationTextSize} />
          <Block marginBottom={2}>
            <Button size="large" type="primary" textColor={isEmilio ? '#6a6a6a' : '#0f202f'} iconColor="primary" onClick={onRegisterClickCallback} label={data.registrationButtonLabel || 'Konto anlegen'} />
          </Block>
          <Block>
            <Block marginBottom={isMinWidthMedium ? 8 : 0} customStyle={{
            textAlign: isMinWidthMedium ? 'center' : undefined
          }}>
              <Link reference={data.registrationBenefitsLink}>
                {data.registrationBenefitsLinkLabel || 'Entdecken Sie die Vorteile der engbersCard'}
              </Link>
            </Block>
          </Block>
        </div>
        {data.guestCheckoutVisible && <div className={styles.loginBox}>
            <Markdown text={data.guestSectionTitle} textAlign={isMinWidthMedium ? data.guestMobileTitleAlign : data.guestDesktopTitleAlign} textSize={data.guestTextSize} />
            <Block marginBottom={2}>
              <Button size="large" type="primary" textColor={isEmilio ? '#6a6a6a' : '#0f202f'} iconColor="primary" onClick={onGuestCheckoutClickCallback} label={data.guestButtonLabel || 'Als Gast fortfahren'} />
            </Block>
            <p className={styles.helpText}>{data.guestInfoText}</p>
          </div>}
      </div>
    </div>;
  if (data.isFullWidth) {
    return <FullPageWidthWrapper>{content}</FullPageWidthWrapper>;
  }
  return content;
};