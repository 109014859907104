import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import getBlockStyle from '@frontastic-engbers/helpers/utils/getBlockStyle';
import { LocationSearchState } from '@frontastic-engbers/types/engbers-custom';
import { Block, Button, InputText } from '@engbers/components/index';
interface ILocationSearch {
  labels: {
    [name: string]: string;
  };
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  search: () => void;
  useFormState: {
    state: LocationSearchState;
    setState: React.Dispatch<React.SetStateAction<LocationSearchState>>;
  };
  showStreetInputs?: boolean;
}
export const LocationSearch: React.FC<ILocationSearch> = ({
  labels,
  loading,
  setLoading,
  search,
  useFormState,
  showStreetInputs
}) => {
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const {
    formatMessage
  } = useFormat({
    name: 'common'
  });
  const {
    state,
    setState
  } = useFormState;
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      searchData: {
        ...state.searchData,
        [e.target.name]: e.target.value
      }
    });
  };
  const handleSearch = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    search();
    setLoading(false);
  };
  return <>
      {showStreetInputs && <>
          <Block customStyle={getBlockStyle(true, isMinWidthMedium)} marginBottom={4}>
            <InputText id="packStationStreetName" name="streetName" type="text" placeholder={labels.labelStreet !== '' ? labels.labelStreet : formatMessage({
          id: 'street.name'
        })} value={state.searchData.streetName} onChange={handleInputChange} onEnter={handleSearch} wrapperCustomStyle={{
          width: '100%'
        }} />
          </Block>
          <Block customStyle={getBlockStyle(false, isMinWidthMedium)} marginBottom={4}>
            <InputText id="packStationStreetNumber" name="streetNumber" type="text" placeholder={labels.labelHouse !== '' ? labels.labelHouse : formatMessage({
          id: 'street.number'
        })} value={state.searchData.streetNumber} onChange={handleInputChange} onEnter={handleSearch} wrapperCustomStyle={{
          width: '100%'
        }} />
          </Block>
        </>}
      <Block customStyle={getBlockStyle(true, isMinWidthMedium)} marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputText id="packStationPostalCode" name="postalCode" type="text" placeholder={labels.labelZip !== '' ? labels.labelZip : formatMessage({
        id: 'zipCodeShort'
      })} value={state.searchData.postalCode} onChange={handleInputChange} onEnter={handleSearch} wrapperCustomStyle={{
        width: '100%'
      }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
      </Block>
      <Block customStyle={getBlockStyle(false, isMinWidthMedium)} marginBottom={4} data-sentry-element="Block" data-sentry-source-file="index.tsx">
        <InputText id="packStationCity" name="city" type="text" placeholder={labels.labelCity !== '' ? labels.labelCity : formatMessage({
        id: 'city'
      })} value={state.searchData.city} onChange={handleInputChange} onEnter={handleSearch} wrapperCustomStyle={{
        width: '100%'
      }} data-sentry-element="InputText" data-sentry-source-file="index.tsx" />
      </Block>
      <Button label="Suche" customStyle={{
      width: isMinWidthMedium ? '100%' : '25%',
      marginLeft: 'auto'
    }} onClick={handleSearch} data-sentry-element="Button" data-sentry-source-file="index.tsx" />
    </>;
};