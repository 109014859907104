import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useRefinementList } from 'react-instantsearch';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { sortSizes } from '@frontastic-engbers/helpers/utils/sizeSorting';
import { InputCheckbox } from '@engbers/components';
import { IFacetProps } from './types';
import { handleFilterDataLayer } from '@frontastic-engbers/helpers/dataLayerHelper';
import styles from './facets.module.scss';
import ApplyFilterButton from './applyFilterButton';
import { useProductList } from '../../context';
const SizeFacet: React.FC<IFacetProps> = ({
  label,
  attribute,
  wrapper: Wrapper = React.Fragment,
  showItemCount = false,
  filterOnSelection = true,
  onApplyFilter
}) => {
  const {
    formatMessage: formatProductMessage
  } = useFormat({
    name: 'product'
  });
  const [selectedSizes, setSelectedSizes] = useState<Set<string>>(new Set());
  const [largeSizesOnly, setLargeSizesOnly] = useState<boolean>(false);
  const {
    updateSelectedFacets
  } = useProductList();
  const {
    refine,
    items
  } = useRefinementList({
    attribute,
    limit: 1000
  });
  const {
    refine: refineLargeSizes,
    items: largeSizeItems
  } = useRefinementList({
    attribute: 'attributes.IsXXL'
  });
  const updateSelectedSizes = useCallback((term: string) => {
    if (filterOnSelection) {
      refine(term);
      handleFilterDataLayer(items, label, term);
      return;
    }
    setSelectedSizes(prevSelection => {
      const updatedSelection = new Set(prevSelection);
      if (updatedSelection.has(term)) {
        updatedSelection.delete(term);
      } else {
        updatedSelection.add(term);
      }
      return updatedSelection;
    });
  }, [filterOnSelection, refine, items, label]);
  const switchLargeSizesOnly = useCallback(() => {
    if (filterOnSelection) {
      refineLargeSizes('1');
      return;
    }
    setLargeSizesOnly(prev => !prev);
  }, [filterOnSelection, refineLargeSizes]);
  const sortedItems = useMemo(() => items.sort(sortSizes), [items]);
  useEffect(() => {
    setSelectedSizes(new Set(items.filter(item => item.isRefined).map(item => item.value)));
  }, [items]);
  useEffect(() => {
    setLargeSizesOnly(largeSizeItems.some(item => item.isRefined && item.value === '1'));
  }, [largeSizeItems]);
  useEffect(() => {
    updateSelectedFacets({
      [attribute]: Array.from(selectedSizes),
      ['attributes.IsXXL']: largeSizesOnly ? ['1'] : undefined
    });
  }, [selectedSizes, largeSizesOnly, attribute, updateSelectedFacets]);
  return <>
      <div className={styles.facetValueWrapper}>
        <div className={styles.tileFacetValues}>
          {sortedItems.map(term => <Wrapper key={term.value + 'wrapper'}>
              <button type="button" className={classnames('GA4-size-filter-trigger', styles.tileFacetValue, selectedSizes.has(term.value) ? styles.checked : undefined)} onClick={() => updateSelectedSizes(term.value)}>
                {term.label}
              </button>
            </Wrapper>)}

          <div className={styles.onlyLargeSizes}>
            {largeSizeItems.filter(term => term.value === '1').map(term => <Wrapper key={term.value + 'wrapper'}>
                  <div>
                    <InputCheckbox id="onlyLargeSizes" name="onlyLargeSizes" checked={largeSizesOnly} onChange={switchLargeSizesOnly} label={`${formatProductMessage({
                id: 'onlyLargeSizes',
                defaultMessage: 'Nur große Größen'
              })}${showItemCount ? ` (${term.count.toString()})` : ``}`} />
                  </div>
                </Wrapper>)}
          </div>
        </div>
      </div>

      {!filterOnSelection && <ApplyFilterButton facets={{
      [attribute]: Array.from(selectedSizes),
      ['attributes.IsXXL']: largeSizesOnly ? ['1'] : undefined
    }} onApplyFilters={() => onApplyFilter(attribute, label, Array.from(selectedSizes))} />}
    </>;
};
export default SizeFacet;