import { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { EngbersOsBottomBar } from '@engbers/components/online-shops';
import { fetchApiHub, getActiveShops, getNavigationTree, getProjectInfo } from '@frontastic-engbers/lib';
import { EcondaWidgetCredentials } from '@frontastic-engbers/types/engbers-custom';
export const EngbersOsBottomBarTastic = ({
  data
}) => {
  const [isMinWidthMedium, setIsMinWidthMedium] = useState<boolean>(false);
  const isMobile = useMediaQuery({
    maxWidth: 1023
  });
  const activeShops = getActiveShops();
  const projectInfo = getProjectInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [categoriesFromServer, setCategoriesfromServer] = useState<any | undefined>(undefined);
  const shop = Object.keys(activeShops).find(key => activeShops[key]) ?? 'engbers';
  const econdaWidgetConfiguration: EcondaWidgetCredentials = data?.topSellerWidgetConfiguration?.dataSource?.credentials;
  useEffect(() => {
    if (isMobile !== isMinWidthMedium) {
      setIsMinWidthMedium(isMobile);
    }
  }, [isMobile]);

  // Fetching Navigation client-side for performance reasons
  // Cache result in session storage to not refetch this information all the time until a new session is opened
  const fetchNavigation = async () => {
    let navigation = JSON.parse(sessionStorage.getItem(`mainNavigation`));
    setIsLoading(true);
    if (navigation && Object.keys(navigation).length) {
      navigation = navigation.nav;
    } else {
      navigation = await fetchApiHub(`/page?path=%2Fheader-navigation-tree`);
      navigation = navigation?.page?.sections?.main?.layoutElements[0].tastics[0].configuration.headerNavigationTree;
      sessionStorage.setItem('mainNavigation', JSON.stringify({
        nav: navigation
      }));
    }
    setCategoriesfromServer(navigation);
    setIsLoading(false);
  };
  const shopNavigationTree = useMemo(() => {
    const isEmilio = projectInfo?.projectId === 'ea';
    if (isEmilio) {
      return getNavigationTree();
    }
    if (categoriesFromServer?.length) {
      return categoriesFromServer?.find(nav => nav.shop === shop)?.tree;
    }
  }, [categoriesFromServer, shop, projectInfo]);
  useEffect(() => {
    if (isMinWidthMedium && !categoriesFromServer?.length) {
      fetchNavigation();
    }
  }, [isMinWidthMedium, categoriesFromServer]);
  return isMinWidthMedium ? <EngbersOsBottomBar items={data.navigationItems.map(item => {
    return {
      behaviour: item.behaviour,
      itemId: item.itemId,
      icon: item.icon,
      reference: item.reference,
      tree: shopNavigationTree || null
    };
  })} isLoadingContent={isLoading} search={{
    searchPlaceholder: data.searchPlaceholder,
    popularCategoriesLabel: data.popularCategoriesLabel,
    popularCategories: data.popularCategories,
    topSellerLabel: data.topSellerLabel,
    topSellerWidgetId: data.topSellerWidgetId,
    topSellerWidgetConfiguration: econdaWidgetConfiguration,
    suggestedProductsLabel: data.suggestedProductsLabel,
    suggestedProductsLimit: data.suggestedProductsLimit,
    suggestedCategoriesLabel: data.suggestedCategoriesLabel,
    allResultsLabel: data.allResultsLabel
  }} extraLinks={data.extraLinks} shopLinks={data.shopLinks} socialMediaLinks={data.socialMediaLinks} algoliaConfig={data.config} /> : null;
};