import React from 'react';
import classnames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useProductList } from '../../context';
import styles from './facets.module.scss';
interface IApplyFilterButtonProps {
  facets: Record<string, string[] | undefined>;
  onApplyFilters?: () => void;
}
const ApplyFilterButton: React.FC<IApplyFilterButtonProps> = ({
  facets,
  onApplyFilters
}) => {
  const {
    formatMessage: formatProductMessage
  } = useFormat({
    name: 'product'
  });
  const {
    applyFilters
  } = useProductList();
  return <div className={styles.applyFilters} data-sentry-component="ApplyFilterButton" data-sentry-source-file="applyFilterButton.tsx">
      <button className={classnames('primary', styles.applyFilterButton)} onClick={() => {
      applyFilters(facets);
      onApplyFilters();
    }}>
        {formatProductMessage({
        id: 'applyProductListFilter',
        defaultMessage: 'Filter anwenden'
      })}
      </button>
    </div>;
};
export default ApplyFilterButton;