import React, { useState } from 'react';
import classnames from 'classnames';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { useImageSEO } from '@frontastic-engbers/helpers/hooks/useImageSEO';
import { useCart } from '@frontastic-engbers/lib/provider';
import { useSnippets } from '@frontastic-engbers/lib/actions/config';
import { CheckoutData, CheckoutErrorsType, ForbiddenFields, LocationSearchState, MediaType, PackstationInput, ShippingType } from '@frontastic-engbers/types/engbers-custom';
import { Image } from '@engbers/components';
import { Address } from '../../address';
import { PackStation } from '../../packstation';
import { StoreDelivery } from '../../store-delivery';
import { ShippingProviderSection } from '../../shipping-provider';
import { usePayments } from '../../context/PaymentProvider';
import { useCheckout } from '../../context/CheckoutDataProvider';
import styles from './shipping-address.module.scss';
interface IShippingAddressSection {
  updateCartData: (validate: boolean, checkoutDataWithPayment?: CheckoutData) => Promise<void>;
  labels: {
    [name: string]: string;
  };
  shippingAddressSection: {
    headlineShippingAddress: string;
    textShippingAddress: string;
  };
  packStationSection: {
    headlinePackstation: string;
    labelPostNo: string;
    labelSelectPackstation: string;
    textPackstation: string;
    errorPackstation: string;
    errorPostNo: string;
  };
  storeDeliverySection: {
    headlineStoreDelivery: string;
    textStoreDelivery: string;
    labelOpeningHours: string;
    labelSelectStoreDelivery: string;
    errorStoreDelivery: string;
    storeClosed: string;
  };
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  shippingSection: {
    headline: string;
    hasStoreDelivery: boolean;
    hasPackStationDelivery: boolean;
    billingAddressBoxIcon: MediaType;
    billingAddressBoxLabel: string;
    shippingAddressBoxIcon: MediaType;
    shippingAddressBoxLabel: string;
    storeDeliveryBoxIcon: MediaType;
    storeDeliveryBoxLabel: string;
    packStationBoxIcon: MediaType;
    packStationBoxLabel: string;
  };
  forbiddenFields: ForbiddenFields;
  checkoutErrors: CheckoutErrorsType;
  setCheckoutErrors: React.Dispatch<React.SetStateAction<CheckoutErrorsType>>;
}
export const ShippingAddress: React.FC<IShippingAddressSection> = ({
  shippingSection,
  updateCartData,
  labels,
  shippingAddressSection,
  packStationSection,
  storeDeliverySection,
  setLoading,
  loading,
  forbiddenFields,
  checkoutErrors,
  setCheckoutErrors
}) => {
  const {
    formatMessage
  } = useFormat({
    name: 'checkout'
  });
  const {
    getTitle
  } = useImageSEO();
  const {
    data: cart
  } = useCart();
  const {
    data: checkoutData,
    updateFormInput,
    availableCountryOptions,
    shippingCosts
  } = useCheckout();
  const {
    fetchPaymentMethods
  } = usePayments();
  const {
    snippetUnselectedShippingAddressHint
  } = useSnippets();
  const [packstationState, setPackstationState] = useState<LocationSearchState>({
    locations: [],
    showAll: false,
    searchData: {
      streetName: '',
      streetNumber: '',
      postalCode: '',
      city: ''
    }
  });
  const [storeDeliveryState, setStoreDeliveryState] = useState<LocationSearchState>({
    locations: [],
    showAll: false,
    searchData: {
      streetName: '',
      streetNumber: '',
      postalCode: '',
      city: ''
    }
  });
  const [packstationInputs, setPackstationInputs] = useState<{
    [key: string]: PackstationInput;
  }>({});
  const selectShippingMethod = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setLoading(true);
      const shippingMethod = e.target.name as ShippingType;
      const country = checkoutData.billingAddress.country === 'CH' ? 'DE' : checkoutData.billingAddress.country;
      if (shippingMethod === 'shippingAddress') {
        updateFormInput('shippingAddress', {
          ...checkoutData.shippingAddress,
          country
        });
      }
      updateFormInput('shippingMethod', shippingMethod);
      updateFormInput('isUnselectedShippingAddress', false);
      const updatedData = {
        ...checkoutData,
        shippingMethod,
        ...(shippingMethod === 'shippingAddress' && {
          shippingAddress: {
            ...checkoutData.shippingAddress,
            country
          }
        })
      };
      await updateCartData(false, updatedData);
      await fetchPaymentMethods(updatedData);
      setLoading(false);
    }
  };
  const shippingMethodBox = (methodName: string, icon: MediaType, label: string, price: string, free?: boolean, order?: number) => {
    return <div className={classnames(styles.shippingMethodBoxWrapper, {
      'order-none': order === 0,
      'order-1': order === 1,
      'order-2': order === 2
    })} data-sentry-component="shippingMethodBox" data-sentry-source-file="index.tsx">
        <label className={classnames(styles.shippingMethodBox, methodName === checkoutData.shippingMethod ? styles.isChecked : undefined)} htmlFor={methodName}>
          <input id={methodName} name={methodName} type="checkbox" checked={methodName === checkoutData.shippingMethod} className={styles.shippingMethodBoxWrap} onChange={selectShippingMethod} />
          <Image src={icon?.media?.file} width={40} alt={getTitle(icon)} title={getTitle(icon)} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
          <div className={styles.shippingMethodBoxTextWrap}>
            <div className={styles.shippingMethodBoxLabel}>{label}</div>
            <div className={classnames(methodName === 'store' || free ? styles.isFree : undefined)}>
              {free ? formatMessage({
              id: 'freeShipping',
              defaultMessage: 'kostenlos'
            }) : price}
            </div>
          </div>
        </label>
      </div>;
  };
  return <div data-sentry-component="ShippingAddress" data-sentry-source-file="index.tsx">
      {checkoutErrors && !checkoutErrors['shippingAddress']?.isValid && checkoutData.isUnselectedShippingAddress && <div className={classnames('mb-3', styles.errorMessage)}>{snippetUnselectedShippingAddressHint}</div>}
      <div className={styles.shippingMethodsWrap}>
        {checkoutData.billingAddress.country !== 'CH' && shippingMethodBox('billingAddress', shippingSection.billingAddressBoxIcon, shippingSection.billingAddressBoxLabel, shippingCosts.billing, cart?.shippingInfo?.discountedPrice?.centAmount === 0, 0)}
        {shippingMethodBox('shippingAddress', shippingSection.shippingAddressBoxIcon, shippingSection.shippingAddressBoxLabel, shippingCosts.shipping, cart?.shippingInfo?.discountedPrice?.centAmount === 0, 1)}
        <ShippingProviderSection setLoading={setLoading} data-sentry-element="ShippingProviderSection" data-sentry-source-file="index.tsx" />
        {checkoutData.shippingMethod === 'shippingAddress' && availableCountryOptions ? <div className={styles.addressFormWrap}>
            <span className={styles.addressFormHeadline}>{shippingAddressSection.headlineShippingAddress}</span>
            <span className={styles.addressFormText}>{shippingAddressSection.textShippingAddress}</span>
            <Address updateCartData={updateCartData} labels={labels} isShipping={true} countries={availableCountryOptions} forbiddenFields={forbiddenFields} setCheckoutErrors={setCheckoutErrors} setLoading={setLoading} />
          </div> : null}
        {shippingSection.hasStoreDelivery ? shippingMethodBox('store', shippingSection.storeDeliveryBoxIcon, shippingSection.storeDeliveryBoxLabel, shippingCosts.store, true, 1) : null}
        {checkoutData.shippingMethod === 'store' ? <div className={styles.addressFormWrap}>
            <span className={styles.addressFormHeadline}>{storeDeliverySection.headlineStoreDelivery}</span>
            <span className={styles.addressFormText}>{storeDeliverySection.textStoreDelivery}</span>
            <StoreDelivery setLoading={setLoading} loading={loading} errorMessage={storeDeliverySection.errorStoreDelivery} labelOpeningHours={storeDeliverySection.labelOpeningHours} labelSelect={storeDeliverySection.labelSelectStoreDelivery} storeClosedText={storeDeliverySection.storeClosed} shippingAddress={checkoutData.storeDeliveryAddress} useStoreDelivery={{
          storeDeliveryState,
          setStoreDeliveryState
        }} labels={labels} updateFormInput={updateFormInput} />
          </div> : null}
        {shippingSection.hasPackStationDelivery ? shippingMethodBox('packStation', shippingSection.packStationBoxIcon, shippingSection.packStationBoxLabel, shippingCosts.packstation, cart?.shippingInfo?.discountedPrice?.centAmount === 0, 1) : null}
        {checkoutData.shippingMethod === 'packStation' ? <div className={styles.addressFormWrap}>
            <span className={styles.addressFormHeadline}>{packStationSection.headlinePackstation}</span>
            <span className={styles.addressFormText}>{packStationSection.textPackstation}</span>
            <PackStation setLoading={setLoading} loading={loading} errorMessage={packStationSection.errorPackstation} errorPostNo={packStationSection.errorPostNo} labelPostNo={packStationSection.labelPostNo} labelSelect={packStationSection.labelSelectPackstation} shippingAddress={checkoutData.packstationAddress} usePackstation={{
          packstationState,
          setPackstationState
        }} usePackstationInputs={{
          packstationInputs,
          setPackstationInputs
        }} labels={labels} updateFormInput={updateFormInput} />
          </div> : null}
      </div>
    </div>;
};