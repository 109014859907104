import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useAccount, useCart } from '@frontastic-engbers/lib';
import { getCustomerPaymentMethods } from '@frontastic-engbers/lib/actions/account';
import { getPaymentMethods as getPayonePaymentMethods } from '@frontastic-engbers/lib/actions/payone';
import { CheckoutData, IPaymentMethod, PaymentMethod as PaymentMethodType, PaymentMethod, PayonePaymentProductAvailability } from '@frontastic-engbers/types/engbers-custom';
import { filterConfiguredPaymentMethods, filterPaymentsWithSecurePayments, getPaymentMethods } from '../utils/PaymentDecisionMaker';
import { NEGATIVE_SOLVENCY_RATING, POSITIVE_SOLVENCY_RATING } from '@frontastic-engbers/helpers/constants/checkout';
import { useCheckout } from './CheckoutDataProvider';
interface PaymentContextType {
  fetchPaymentMethods: (checkoutData: CheckoutData, solvencyCheckAccepted?: boolean) => Promise<PaymentMethod[]>;
  filteredPaymentMethods: PaymentMethod[];
  setFilteredPaymentMethods: React.Dispatch<React.SetStateAction<PaymentMethod[]>>;
  hasInvoiceLockedProducts: boolean;
  setHasInvoiceLockedProducts: React.Dispatch<React.SetStateAction<boolean>>;
  hostedTokenizationId: string;
  setHostedTokenizationId: React.Dispatch<React.SetStateAction<string>>;
  paymentProductId: number;
  setPaymentProductId: React.Dispatch<React.SetStateAction<number>>;
  isIframePayment: boolean;
  setIsIframePayment: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPaymentMethod: PaymentMethodType;
  setSelectedPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethodType>>;
  resetPayment: () => void;
  checkSolvency: () => Promise<boolean>;
}
export const PaymentContext = createContext<PaymentContextType>({
  fetchPaymentMethods: async () => [],
  filteredPaymentMethods: [],
  setFilteredPaymentMethods() {},
  hasInvoiceLockedProducts: false,
  setHasInvoiceLockedProducts() {},
  hostedTokenizationId: '',
  setHostedTokenizationId() {},
  paymentProductId: undefined,
  setPaymentProductId() {},
  isIframePayment: false,
  setIsIframePayment() {},
  selectedPaymentMethod: undefined,
  setSelectedPaymentMethod() {},
  resetPayment() {},
  checkSolvency: async () => false
});
const PaymentProvider = ({
  paymentMethods,
  children
}: React.PropsWithChildren<any>) => {
  const {
    data: cart
  } = useCart();
  const {
    account,
    recheckSolvency
  } = useAccount();
  const {
    data: checkoutData,
    customerOrigin,
    updateFormInput
  } = useCheckout();
  const [availablePayonePayments, setAvailablePayonePayments] = useState<PayonePaymentProductAvailability>();
  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState<PaymentMethod[]>();
  const [hasInvoiceLockedProducts, setHasInvoiceLockedProducts] = useState<boolean>(false);
  const [hostedTokenizationId, setHostedTokenizationId] = useState<string>('');
  const [paymentProductId, setPaymentProductId] = useState<number>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodType>();
  const [isIframePayment, setIsIframePayment] = useState<boolean>(false);
  const fetchPaymentMethods = useCallback(async (checkoutData: CheckoutData, solvencyCheckAccepted = false): Promise<PaymentMethod[]> => {
    const mappedPaymentMethods = await getConfiguredPaymentMethods(paymentMethods);
    try {
      const {
        filteredPaymentMethods,
        filterByWebservice,
        hasLockedProducts
      } = getPaymentMethods(mappedPaymentMethods, checkoutData, account, cart);
      setHasInvoiceLockedProducts(hasLockedProducts);
      if (!filterByWebservice) {
        setFilteredPaymentMethods(filteredPaymentMethods);
        return;
      }
      const result = await getCustomerPaymentMethods(filteredPaymentMethods, checkoutData, checkoutData.solvency.accepted || solvencyCheckAccepted, customerOrigin);
      setFilteredPaymentMethods(result.filteredPaymentMethods);
      setSolvencyResult(checkoutData, result.filteredPaymentMethods);
      return result.filteredPaymentMethods;
    } catch (e) {
      const filteredPaymentMethods = filterPaymentsWithSecurePayments(mappedPaymentMethods);
      setFilteredPaymentMethods(filteredPaymentMethods);
      return filteredPaymentMethods;
    }
  }, [account, customerOrigin, cart, paymentMethods]);
  const checkSolvency = useCallback(async (): Promise<boolean> => {
    try {
      const res = await recheckSolvency(filteredPaymentMethods, checkoutData.billingAddress, checkoutData.solvency.accepted, customerOrigin);
      if (!res.isValid) {
        if (res.payments) {
          setFilteredPaymentMethods(res.payments);
          setSolvencyResult(checkoutData, res.payments);
        }
        return false;
      }
      return true;
    } catch (error) {
      const filteredMethods = filterPaymentsWithSecurePayments(filteredPaymentMethods);
      setFilteredPaymentMethods(filteredMethods);
      return false;
    }
  }, [checkoutData, filteredPaymentMethods, customerOrigin]);
  const getConfiguredPaymentMethods = useCallback(async (paymentMethods: IPaymentMethod[]) => {
    let availablePayonePaymentsNew = availablePayonePayments;
    if (!availablePayonePayments) {
      availablePayonePaymentsNew = await getPayonePaymentMethods(cart?.sum?.currencyCode);
      setAvailablePayonePayments(availablePayonePaymentsNew);
    }
    return filterConfiguredPaymentMethods(paymentMethods, availablePayonePaymentsNew);
  }, [availablePayonePayments, cart?.sum?.currencyCode]);
  const setSolvencyResult = (checkoutData: CheckoutData, paymentMethods?: PaymentMethod[]) => {
    if (!checkoutData.solvency.accepted || !paymentMethods) {
      return;
    }

    // invoice canUse state determines result for all unsafe payment methods
    const paymentMethod = paymentMethods.find((paymentMethod: PaymentMethod) => paymentMethod.type === 'invoice');
    updateFormInput('solvency', {
      ...checkoutData.solvency,
      result: paymentMethod?.canUse ? POSITIVE_SOLVENCY_RATING : NEGATIVE_SOLVENCY_RATING
    });
  };
  const resetPayment = useCallback(() => {
    setHasInvoiceLockedProducts(false);
    setIsIframePayment(false);
    setSelectedPaymentMethod(null);
    setHostedTokenizationId('');
    updateFormInput('paymentMethod', null);
  }, []);
  const value = useMemo(() => ({
    fetchPaymentMethods,
    filteredPaymentMethods,
    setFilteredPaymentMethods,
    hasInvoiceLockedProducts,
    setHasInvoiceLockedProducts,
    hostedTokenizationId,
    setHostedTokenizationId,
    paymentProductId,
    setPaymentProductId,
    isIframePayment,
    setIsIframePayment,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    resetPayment,
    checkSolvency
  }), [fetchPaymentMethods, filteredPaymentMethods, hasInvoiceLockedProducts, hostedTokenizationId, paymentProductId, isIframePayment, selectedPaymentMethod, resetPayment, checkSolvency]);
  return <PaymentContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="PaymentProvider" data-sentry-source-file="PaymentProvider.tsx">{children}</PaymentContext.Provider>;
};
export default PaymentProvider;
export const usePayments = () => useContext(PaymentContext);