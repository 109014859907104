import { TagManager } from '@frontastic-engbers/lib/lib/tracking';

export const handleFilterDataLayer = (items: any[], label: string, currentValue?: string) => {
  const refinedFilters = items.filter((term) => term.isRefined).map((term) => term.value);

  if (refinedFilters.includes(currentValue)) {
    const index = refinedFilters.indexOf(currentValue);

    refinedFilters.splice(index, 1);
  } else {
    refinedFilters.push(currentValue);
  }

  new TagManager().customEvent('filter', { label: label, value: refinedFilters }).executePush();
};

export const handleFilterDataLayerByValues = (facetId: string, selectedValues: string[]) => {
  new TagManager().customEvent('filter', { label: facetId, value: selectedValues }).executePush();
};
